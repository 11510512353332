import React from 'react';

import CartLineItemsSection from 'components/cart/line-items-section';
import CartEmptyState from 'components/cart/empty-state';
import CartSubtotal from 'components/cart/subtotal';
import CartActions from 'components/cart/actions';

import { connect } from 'react-redux';
import provideStoreToComponent from 'app-provider';
import { LineItem } from 'types';
import numeral from 'numeral';
import GlobalState from 'GlobalState';

interface Props {
  order: GlobalState['currentOrder'];
}

interface State {
  visible: boolean;
}

class CartLineItems extends React.Component<Props, State> {
  state = {
    visible: true
  };

  get lineItems(): LineItem[] {
    const { order } = this.props;

    if (order != null && order.line_items != null) {
      return order.line_items;
    } else {
      return [];
    }
  }

  toggleVisibility = () => {
    this.setState((state) => {
      return {
        ...state,
        visible: !state.visible
      };
    });
  };

  renderLineItems() {
    const physicalLineItems = this.lineItems.filter((lineItem) => {
      return lineItem.shipping_category !== 'Digital';
    });

    const digitalLineItems = this.lineItems.filter((lineItem) => {
      return lineItem.shipping_category === 'Digital';
    });

    let physicalLineItemsSection = null;
    let digitalLineItemsSection = null;

    if (physicalLineItems.length > 0) {
      physicalLineItemsSection = (
        <CartLineItemsSection heading="Goods" lineItems={physicalLineItems} />
      );
    }

    if (digitalLineItems.length > 0) {
      digitalLineItemsSection = (
        <CartLineItemsSection heading="Fonts" lineItems={digitalLineItems} />
      );
    }

    if (physicalLineItemsSection != null || digitalLineItemsSection != null) {
      return (
        <div>
          {physicalLineItemsSection}
          {digitalLineItemsSection}
        </div>
      );
    } else {
      return <CartEmptyState />;
    }
  }

  renderSubtotal() {
    const { order } = this.props;

    if (order != null && this.lineItems.length > 0) {
      return <CartSubtotal order={order} />;
    } else {
      return null;
    }
  }

  renderActions() {
    if (this.lineItems.length > 0) {
      return <CartActions />;
    } else {
      return null;
    }
  }

  render() {
    const csrfToken = $('meta[name="csrf-token"]').attr('content');
    const subtotal = this.lineItems.reduce((sum, item) => {
      return sum + item.total;
    }, 0);

    const formattedSubtotal = numeral(subtotal).format('$0,0.00');

    return (
      <div className="CartLineItems">
        {this.renderLineItems()}

        <div className="CartLineItem-complimentary-actions">
          <div className="CartActions">
            <p className="CartSubtotal">Subtotal {formattedSubtotal}</p>

            <div className="CartActions-primary">
              <form action="/cart" method="post">
                <input name="_method" type="hidden" value="patch" />
                <input
                  name="authenticity_token"
                  type="hidden"
                  value={csrfToken}
                />
                <input
                  name="checkout"
                  type="submit"
                  value="Proceed to Checkout"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default provideStoreToComponent(
  connect((state: GlobalState) => {
    return {
      order: state.currentOrder
    };
  })(CartLineItems)
);
