import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { path } from 'utils/location';
import provideStoreToComponent from 'app-provider';
import { updateSelectedTaxon } from 'modules/products/action-creators';
import TaxonNavigationItem from './taxon-navigation-item';
import GlobalState from 'GlobalState';
import { Taxon } from 'types';

const BOOK_PROJECT_PATH = '/hi/the-process-is-the-inspiration';

interface Props {
  taxon: Taxon;
  selectedTaxonId: number;
}

interface State {
  isExpanded: boolean;
  togglesAreVisible: boolean;
}

export class TaxonNavigationGroup extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const currentPath = path();
    const { taxon } = this.props;

    const isExpanded = currentPath.indexOf(`/${taxon.permalink}`) === 0;

    this.state = {
      isExpanded: !isBookProjectPath() && isExpanded,
      togglesAreVisible: false
    };

    this.toggleExpanded = this.toggleExpanded.bind(this);
  }

  toggleExpanded() {
    this.setState({
      isExpanded: !this.state.isExpanded
    });
  }

  render() {
    const { taxon } = this.props;

    let href = `/${taxon.permalink}`;

    if (taxon.permalink.indexOf('http') > -1) {
      href = taxon.permalink;
    }

    return (
      <div
        className={classNames('TaxonNavigationGroup', {
          'TaxonNavigationGroup--toggles-visible': this.state.togglesAreVisible,
          'TaxonNavigationGroup--expanded': this.state.isExpanded
        })}
      >
        <div className="TaxonNavigationGroup-header">
          <div className="TaxonNavigationGroup-name-container">
            <a className="TaxonNavigationGroup-name" href={href}>
              {taxon.name}
            </a>

            {taxon.children.length > 0 && (
              <button
                className="TaxonNavigationGroup-toggle"
                onClick={this.toggleExpanded}
              />
            )}
          </div>
        </div>

        <div className="TaxonNavigationGroup-children">
          {taxon.children.map((childTaxon) => (
            <TaxonNavigationItem key={childTaxon.id} taxon={childTaxon} />
          ))}
        </div>
      </div>
    );
  }
}

function isBookProjectPath() {
  return path().indexOf(BOOK_PROJECT_PATH) === 0;
}

export default provideStoreToComponent(
  connect(
    (state: GlobalState) => {
      let selectedTaxonId = null;

      if (state.products.selectedTaxonId != null) {
        selectedTaxonId = state.products.selectedTaxonId;
      } else if (state.product != null) {
        selectedTaxonId = state.product.primaryTaxonId;
      }

      return {
        selectedTaxonId
      };
    },
    (dispatch) => {
      return {
        updateSelectedTaxon(selectedTaxonId: number) {
          dispatch(updateSelectedTaxon(selectedTaxonId));
        }
      };
    }
  )(TaxonNavigationGroup)
);
