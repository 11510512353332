import React from 'react';
import classNames from 'classnames';
import $ from 'jquery';

const DOWNLOAD_URL = '/font_archives/status';

const fetchDownloadURL = (jobId: string) => {
  const fetchURL = (
    resolve: (value?: unknown) => void,
    reject: (value?: unknown) => void
  ) => {
    $.getJSON(DOWNLOAD_URL, { job_id: jobId }).then((response) => {
      switch (response.status) {
        case 'working':
        case 'queued':
          setTimeout(fetchURL.bind(null, resolve, reject), 1000);
          break;
        case 'complete':
          resolve(response.archive_url);
          break;
        default:
          reject();
      }
    }, reject);
  };

  return new Promise((resolve, reject) => {
    setTimeout(fetchURL.bind(null, resolve, reject), 1000);
  });
};

interface Props {
  licenseId: number;
  downloadToken: string;
  displayAsLink: boolean;
}

interface State {
  isProcessing: boolean;
  downloadURL?: string;
}

class FontDownloadButton extends React.Component<Props, State> {
  static defaultProps = {
    downloadToken: null,
    displayAsLink: false
  };

  state: State = {
    isProcessing: false
  };

  downloadArchive = () => {
    const { licenseId, downloadToken } = this.props;

    const data: { font_license_id: number; font_download_token?: string } = {
      font_license_id: licenseId
    };

    if (typeof downloadToken === 'string') {
      data.font_download_token = downloadToken;
    }

    const options = {
      url: '/font_archives',
      method: 'POST',
      data
    };

    this.setState({ isProcessing: true });

    const onReject = () => {
      alert('Failed to download! Please try again.');

      this.setState({ isProcessing: false });
    };

    $.ajax(options).then((response) => {
      fetchDownloadURL(response.job_id).then((downloadURL: string) => {
        this.setState({ downloadURL, isProcessing: false });
      }, onReject);
    }, onReject);
  };

  renderIframe() {
    const { downloadURL } = this.state;

    if (downloadURL !== null) {
      return <iframe src={downloadURL} style={{ display: 'none' }} />;
    } else {
      return null;
    }
  }

  render() {
    const { isProcessing } = this.state;

    const buttonText = isProcessing ? 'Processing...' : 'Download';

    const classes = classNames('FontDownloadButton', {
      'FontDownloadButton--button': !this.props.displayAsLink,
      'FontDownloadButton--link': this.props.displayAsLink,
      'FontDownloadButton--processing': this.state.isProcessing
    });

    return (
      <div className={classes}>
        <button onClick={this.downloadArchive} disabled={isProcessing}>
          {buttonText}
        </button>
        {this.renderIframe()}
      </div>
    );
  }
}

export default FontDownloadButton;
