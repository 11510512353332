import React, { createRef } from 'react';
import $ from 'jquery';

interface Props {
  defaultChecked: boolean;
}

class CheckoutUseBillingCheckbox extends React.Component<Props> {
  inputNode = createRef<HTMLInputElement>();

  componentDidMount() {
    this.toggleShippingForm();
  }

  toggleShippingForm = () => {
    if (this.inputNode.current != null) {
      const { checked } = this.inputNode.current;

      $('[data-ref="shipping-address"]').toggle(!checked);
    }
  };

  render() {
    return (
      <label className="custom-checkbox">
        <input
          ref={this.inputNode}
          type="checkbox"
          name="order[use_billing]"
          value="1"
          defaultChecked={this.props.defaultChecked}
          onChange={this.toggleShippingForm}
        />
        &nbsp;Use Billing Address
      </label>
    );
  }
}

export default CheckoutUseBillingCheckbox;
