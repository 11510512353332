import React from 'react';
import { connect } from 'react-redux';
import provideStoreToComponent from 'app-provider';
import { toggleSearchVisibility } from 'modules/options/action-creators';

interface Props {
  onClick: React.ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
}

const SearchButton: React.FC<Props> = (props) => {
  return <button className="SearchButton" onClick={props.onClick} />;
};

export default provideStoreToComponent(
  connect(null, (dispatch) => {
    return {
      onClick() {
        dispatch(toggleSearchVisibility());
      }
    };
  })(SearchButton)
);
