import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import FlashMessage from 'components/flash-message';
import { connect } from 'react-redux';
import provideStoreToComponent from 'app-provider';

import { Message } from 'types';
import GlobalState from 'GlobalState';

interface Props {
  messages: Array<Message>;
}

class FlashMessages extends React.Component {
  props: Props;

  renderMessages() {
    return this.props.messages.map((message, index) => {
      return <FlashMessage key={index} message={message} />;
    });
  }

  render() {
    return (
      <div className="FlashMessages">
        <ReactCSSTransitionGroup
          transitionName="FlashMessage"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}
        >
          {this.renderMessages()}
        </ReactCSSTransitionGroup>
      </div>
    );
  }
}

export default provideStoreToComponent(
  connect((state: GlobalState) => {
    return {
      messages: state.flashMessages
    };
    // @ts-ignore;
  })(FlashMessages)
);
