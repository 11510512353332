import { createContext } from 'react';

import { LicenseType } from 'types';

const FontLicensingContext = createContext<{
  licenseType: LicenseType | null;
}>({
  licenseType: null
});

export default FontLicensingContext;
