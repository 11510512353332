declare global {
  interface Window {
    recaptchaSiteKey: string;

    bugsnagClient?: {
      notify(error: Error): void;
    };

    fbq(action: string, eventName: string, options?: {}): void;

    _learnq?: Array<[string, string, object] | [string, object]>;

    grecaptcha: {
      ready(callback: () => void): void;
      execute(siteKey: string, options: { action: string }): Promise<string>;
    };

    KlaviyoSubscribe?: {
      attachToForms(
        selector: string,
        options: {
          hide_form_on_success?: boolean;
          success_message?: string;
          success?(): void;
        }
      ): void;
    };
  }
}

export type LicenseType = 'desktop' | 'web' | 'app';

export interface HomePageSection {
  id: number;
  position: number;
  section_name: string | null;
  home_page_section_type: HomePageSectionType | null;
  home_page_items: HomePageItem[];
}

export interface HomePageSectionType {
  id: number;
  name: 'large' | 'small';
  css_class: string;
  supports_video: boolean;
}

export interface HomePageItem {
  id: number;
  label: string;
  link_url: string | null;
  img_link: string | null;
  has_product: boolean;
}

export interface User {
  id: number;
  receive_email: boolean;
}

export interface FontFamily {
  id: number;
  name: string;
  description: string;
  styles: FontStyle[];
  type_testers: FontTypeTester[];
  specimens: FontSpecimen[];
  ornaments: FontOrnament[];
  features: FontFeature[];
}

export interface FontStyle {
  id: number;
  name: string;
  nameAsSvg: string;
  glyphs: string[];
}

export interface FontTypeTester {
  id: number;
  fontStyleId: number;
  defaultText: string;
  defaultSize: number;
  minSize: number;
  style: { [property: string]: string };
  name: string;
  lineHeight: string;
  singleLine: boolean;
}

export interface FontSpecimen {
  id: number;
  title: string;
  assetURL: string;
}

export interface FontOrnament {
  id: number;
  title: string;
  assetURL: string;
}

export interface FontFeature {
  id: number;
  content: string;
  assetURL: string;
}

export interface Product {
  id: number;
  name: string;
  price: number;
  in_stock: boolean;
  product_path: string;
  master_variant_id: number;
  options: OptionType[];
  variants: Variant[];
  images: ProductImage[];
  media: ProductMedia[];
  properties: {
    [key: string]: string;
  };
}

export interface Variant {
  id: number;
  price: number;
  in_stock: boolean;
  options: OptionValue[];
}

export interface OptionType {
  id: number;
  name: string;
  values: OptionValue[];
}

export interface OptionValue {
  id: number;
  name: string;
  option_type_id: number;
}

export interface LineItem {
  id: number;
  price: number;
  quantity: number;
  total: number;
  variant_id: number;
  options_text: string[];
  font_license_type: LicenseType;
  license_style_ids: number[];
  font_license_description: string;
  shipping_category: 'Physical' | 'Digital';
  image_urls:
    | {
        preview: string;
      }
    | {
        small: string;
        large: string;
      };

  product: {
    id: number;
    sku: string | null;
    name: string;
    taxon_names: string[];
    url: string;
    image_url: string;
    font_family_id: number | null;
  };
}

export interface Order {
  id: number;
  number: string;
  line_items: LineItem[];
}

export interface Address {
  firstname: string;
  lastname: string;
  address1: string;
  address2: string | null;
  city: string;
  state: string;
  zipcode: string;
  country: string;
}

export interface Message {
  content: string;
  messageType: 'notice' | 'alert';
}

export interface ProductImage {
  id: number;
  key_image: boolean;
  variant_ids: Array<number>;
  urls: {
    large_mobile: string;
    large_mobile_2x: string;
    small: string;
    small_2x: string;
    large: string;
    large_2x: string;
    extra_large: string;
    extra_large_2x: string;
  };
}

export interface Taxon {
  id: number;
  name: string;
  permalink: string;
  children: Taxon[];
}

export interface StoreItem {
  id: number;
  name: string;
  taxonomy_name: string;
  url: string;
  width: number;
  height: number;
  image_url:
    | {
        small: string;
        small_2x: string;
        product: string;
        product_2x: string;
        large: string;
        large_2x: string;
      }
    | string;
  hover_image_url?: string;
}

export interface ProductVideo {
  id: number;
  vimeo_id: string;
  preview_url: string;
}

export type ProductMedia = ProductMediaImage | ProductMediaVideo;

export interface ProductMediaImage {
  type: 'image';
  content: ProductImage;
  variant_ids: number[];
}

export function isProductMediaImage(
  value?: ProductMedia
): value is ProductMediaImage {
  return value != null && value.type === 'image';
}

export interface ProductMediaVideo {
  type: 'video';
  content: ProductVideo;
  variant_ids: number[];
}

export function isProductMediaVideo(
  value?: ProductMedia
): value is ProductMediaVideo {
  return value != null && value.type === 'video';
}

export interface LicenseOption {
  id: number;
  label: string;
  multiplier?: number;
}
