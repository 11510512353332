import React from 'react';

interface Props {
  urls: { small: string; large: string };
  selectedUrl?: 'small' | 'large' | 'product';
  alt?: string;
}

const ProductImage: React.FC<Props> = (props) => {
  const { urls, selectedUrl = 'small', alt } = props;

  const src = urls[selectedUrl];

  return (
    <span className="ProductImage">
      <img src={src} alt={alt} />
    </span>
  );
};

export default ProductImage;
