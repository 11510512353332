import React from 'react';
import classNames from 'classnames';

interface Props {
  activeSection: string | null;
  sectionNames: string[];
  scrollToSection(sectionName: string): void;
  sectionIsVisible(sectionName: string): boolean;
}

const FontFamilyPageNav: React.FC<Props> = (props) => {
  const { sectionNames, sectionIsVisible, activeSection, scrollToSection } =
    props;

  const navSections = sectionNames.reduce((sections, sectionName) => {
    if (sectionIsVisible(sectionName)) {
      const classes = classNames({
        current: activeSection === sectionName
      });

      const elem = (
        <li key={sectionName} className={classes}>
          <a
            href="#"
            onClick={() => {
              scrollToSection(sectionName);
            }}
          >
            {sectionName}
          </a>
        </li>
      );

      return [...sections, elem];
    }

    return sections;
  }, []);

  return <ul className="font-navigation">{navSections}</ul>;
};

export default FontFamilyPageNav;
