import React, { memo } from 'react';
import { FontStyle } from 'types';
import { useDeclarationClassName } from 'modules/font-declarations';
import classNames from 'classnames';

interface Props {
  style: FontStyle;
}

const FontFamilyGlyphsItem: React.FC<Props> = (props) => {
  const { style } = props;
  const className = useDeclarationClassName(style.id);

  return (
    <div>
      <h3 className="FontGlyphs-heading">{style.name}</h3>

      <div className={classNames('FontGlyphs-glyph-container', className)}>
        {style.glyphs.map((glyph, index) => (
          <div key={index} className="FontGlyphs-glyph">
            {glyph}
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(FontFamilyGlyphsItem);
