import React from 'react';
import classNames from 'classnames';

interface Props {
  taxon: {
    name: string;
    permalink: string;
  };
}

const TaxonNavigationItem: React.FC<Props> = (props) => {
  const { taxon } = props;
  const href = `/${taxon.permalink}`;
  const text = taxon.name;

  const classes = classNames('TaxonNavigationItem', {
    'TaxonNavigationItem--current': window.location.pathname.indexOf(href) === 0
  });

  return (
    <div className={classes}>
      <a href={href}>{text}</a>
    </div>
  );
};

export default TaxonNavigationItem;
