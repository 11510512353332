import React from 'react';
import { find } from 'lodash';

import globalData from 'utils/global-data';
import Select from 'components/core/select';

interface Props {
  countries: {
    id: number;
    name: string;
    states: { id: number; name: string }[];
  }[];
  namePrefix: string;
  countryId?: number;
  stateId?: number;
  stateName?: string;
}

interface State {
  selectedCountryId: number;
}

class AddressCountryState extends React.PureComponent<Props, State> {
  static defaultProps = (() => {
    const { countriesJSON } = globalData();

    return {
      countries: countriesJSON ? JSON.parse(countriesJSON) : []
    };
  })();

  state = {
    selectedCountryId: this.props.countryId || this.props.countries[0].id
  };

  handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountryId = parseInt(event.currentTarget.value);

    this.setState({ selectedCountryId });
  };

  renderCountries() {
    const { countries } = this.props;

    return countries.map((country) => {
      return (
        <option key={country.id} value={country.id}>
          {country.name}
        </option>
      );
    });
  }

  renderStates() {
    const { selectedCountryId } = this.state;
    const { countries, namePrefix, stateName, stateId } = this.props;

    const country = find(countries, { id: selectedCountryId });

    if (country != null && country.states.length > 0) {
      const { states } = country;

      const options = states.map((state) => {
        return (
          <option key={state.id} value={state.id}>
            {state.name}
          </option>
        );
      });

      return (
        <Select
          defaultValue={stateId != null ? stateId.toString() : ''}
          name={`${namePrefix}[state_id]`}
          includeBlank={true}
        >
          {options}
        </Select>
      );
    }

    return (
      <input
        type="text"
        defaultValue={stateName}
        name={`${namePrefix}[state_name]`}
      />
    );
  }

  render() {
    const { namePrefix, countryId } = this.props;

    return (
      <div className="AddressCountryState">
        <div className="form-group">
          <div className="control-container">
            <label className="control-label">Country</label>
            <div className="control-input">
              <Select
                defaultValue={countryId != null ? countryId.toString() : ''}
                onChange={this.handleCountryChange}
                name={`${namePrefix}[country_id]`}
                includeBlank={true}
              >
                {this.renderCountries()}
              </Select>
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="control-container">
            <label className="control-label">State</label>
            <div className="control-input">{this.renderStates()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddressCountryState;
