import React from 'react';

import StoreItems, { Props as StoreItemsProps } from './store-items';

interface Props extends StoreItemsProps {
  heading: string;
}

const SearchResultsGroup: React.FC<Props> = (props) => {
  return (
    <div className="ProductsSearchResultsGroup">
      <h2>{props.heading}</h2>

      <StoreItems {...props} />
    </div>
  );
};

export default SearchResultsGroup;
