import React from 'react';
import numeral from 'numeral';

import { connect } from 'react-redux';
import provideStoreToComponent from 'app-provider';

import { currentProduct, selectedVariant } from 'modules/product/getters';

import { Product, Variant } from 'types';
import GlobalState from 'GlobalState';

class ProductPrice extends React.Component {
  props: {
    selectedVariant?: Variant;
    currentProduct?: Product;
  };

  render() {
    const { selectedVariant, currentProduct } = this.props;
    let price = '';
    let oldPrice = '';

    if (
      currentProduct != null &&
      currentProduct.properties &&
      currentProduct.properties.old_price
    ) {
      oldPrice = numeral(currentProduct.properties.old_price).format('$0,0.00');
    }

    if (selectedVariant != null) {
      price = numeral(selectedVariant.price).format('$0,0.00');
    } else if (currentProduct) {
      price = numeral(currentProduct.price).format('$0,0.00');
    }

    if (oldPrice !== '') {
      return (
        <div>
          <div className="ProductPrice OldPrice">{oldPrice}</div>
          <div className="ProductPrice">{price}</div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="ProductPrice">{price}</div>
        </div>
      );
    }
  }
}

export default provideStoreToComponent(
  connect((state: GlobalState) => {
    return {
      selectedVariant: selectedVariant(state),
      currentProduct: currentProduct(state)
    };
    // @ts-ignore;
  })(ProductPrice)
);
