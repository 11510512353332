import React from 'react';
import classNames from 'classnames';

interface Props {
  visibleLicenseTypes: string[];
  licenseText: {
    desktop: string;
    web: string;
    app: string;
  };
}

interface State {
  selectedType: string;
}

class FontLicensingLicense extends React.Component<Props, State> {
  state = {
    selectedType: this.props.visibleLicenseTypes[0]
  };

  selectType = (selectedType: string) => {
    this.setState({ selectedType });
  };

  renderTabs() {
    return this.props.visibleLicenseTypes.map((type) => {
      return (
        <Tab
          key={type}
          type={type}
          selectedType={this.state.selectedType}
          onSelect={this.selectType}
        />
      );
    });
  }

  render() {
    const licenseText = {
      __html: this.props.licenseText[this.state.selectedType]
    };

    return (
      <div className="FontLicensingLicenses">
        <div className="license-navigation">{this.renderTabs()}</div>

        <div className="license-text" dangerouslySetInnerHTML={licenseText} />
      </div>
    );
  }
}

const Tab: React.FC<{
  type: string;
  selectedType: string;
  onSelect(selectedType: string): void;
}> = (props) => {
  const { type, selectedType, onSelect } = props;

  const classes = classNames('license-navigation-item', {
    active: type === selectedType
  });

  return (
    <a className={classes} onClick={onSelect.bind(null, type)}>
      {type}
    </a>
  );
};

export default FontLicensingLicense;
