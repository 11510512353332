declare global {
  interface Window {
    StripeLoader: {
      isLoaded: boolean;
      didLoad(): void;
      onLoad(callback: () => void): () => void;
    };
  }
}

import React, { createRef } from 'react';
import { range } from 'lodash';

import FormRailsHiddenInputs from 'components/core/form/rails-hidden-inputs';
import CoreSelect from 'components/core/select';

import { Address } from 'types';

interface Props {
  paymentMethodId: number;
  action: string;
  billAddress: Address;
  flashError?: string;
}

interface State {
  stripeIsLoaded: boolean;
  isSubmitting: boolean;
  errors: string[];
  recaptchaToken: string | null;
  stripeToken: string | null;
  ccType: string | null;
  lastDigits: string | null;
  expMonth: number | null;
  expYear: number | null;
}

class PaymentForm extends React.PureComponent<Props, State> {
  state = {
    stripeIsLoaded: window.StripeLoader.isLoaded,
    isSubmitting: false,
    errors: [],
    recaptchaToken: null,
    stripeToken: null,
    ccType: null,
    lastDigits: null,
    expMonth: null,
    expYear: null
  };

  node: React.RefObject<HTMLFormElement> = createRef();
  stripeLoaderUnsubscribe?: Function;

  componentDidMount() {
    if (!this.state.stripeIsLoaded) {
      this.stripeLoaderUnsubscribe = window.StripeLoader.onLoad(() => {
        this.setState({
          stripeIsLoaded: true
        });
      });
    }
  }

  componentWillUnmount() {
    if (typeof this.stripeLoaderUnsubscribe === 'function') {
      this.stripeLoaderUnsubscribe();
    }
  }

  stripeResponseHandler = (
    status: unknown,
    response: {
      error?: { message: string };
      id?: string;
      card?: {
        brand: string;
        last4: string;
        exp_month: number;
        exp_year: number;
      };
    }
  ) => {
    if (response.error != null) {
      this.setState({
        isSubmitting: false,
        errors: [response.error.message]
      });
    } else if (response.id != null && response.card != null) {
      this.setState({
        stripeToken: response.id,
        ccType: response.card.brand,
        lastDigits: response.card.last4,
        expMonth: response.card.exp_month,
        expYear: response.card.exp_year
      });

      if (this.node.current != null) {
        this.node.current.submit();
      }
    }
  };

  handleSubmit = (event: React.FormEvent) => {
    if (this.state.stripeToken === null) {
      event.preventDefault();

      this.setState({
        isSubmitting: true,
        errors: []
      });

      if (window.grecaptcha == null) {
        // @ts-ignore
        Stripe.card.createToken(this.node.current, this.stripeResponseHandler);
      } else {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(window.recaptchaSiteKey, { action: 'checkout_payment' })
            .then((token: string) => {
              this.setState({
                recaptchaToken: token
              });

              // @ts-ignore
              Stripe.card.createToken(
                this.node.current,
                this.stripeResponseHandler
              );
            });
        });
      }
    }
  };

  renderHiddenInputs() {
    if (typeof this.state.stripeToken === 'string') {
      const paramPrefix = `payment_source[${this.props.paymentMethodId}]`;

      const {
        recaptchaToken,
        stripeToken,
        ccType,
        lastDigits,
        expMonth,
        expYear
      } = this.state;

      return (
        <>
          <input
            type="hidden"
            name="recaptcha_token"
            value={recaptchaToken || ''}
          />

          <input
            type="hidden"
            name="order[payments_attributes][][payment_method_id]"
            value={this.props.paymentMethodId}
          />

          <input
            type="hidden"
            name={`${paramPrefix}[gateway_payment_profile_id]`}
            value={stripeToken || ''}
          />

          <input
            type="hidden"
            name={`${paramPrefix}[cc_type]`}
            value={ccType || ''}
          />

          <input
            type="hidden"
            name={`${paramPrefix}[last_digits]`}
            value={lastDigits || ''}
          />

          <input
            type="hidden"
            name={`${paramPrefix}[month]`}
            value={expMonth || ''}
          />

          <input
            type="hidden"
            name={`${paramPrefix}[year]`}
            value={expYear || ''}
          />
        </>
      );
    } else {
      return null;
    }
  }

  renderMonths() {
    return range(1, 13).map((month) => {
      return (
        <option key={month} value={month}>
          {month}
        </option>
      );
    });
  }

  renderYears() {
    const currentYear = new Date().getFullYear();

    return range(currentYear, currentYear + 20).map((year) => {
      return (
        <option key={year} value={year}>
          {year}
        </option>
      );
    });
  }

  renderErrors() {
    const { flashError } = this.props;

    let errors: string[] = [...this.state.errors];

    if (flashError != null) {
      errors.push(flashError);
    }

    return errors.map((error, index) => {
      return (
        <div key={index} className="form-error">
          {error}
        </div>
      );
    });
  }

  render() {
    const { stripeIsLoaded, isSubmitting } = this.state;

    if (!stripeIsLoaded) {
      return (
        <div className="CheckoutPaymentForm CheckoutPaymentForm--loading">
          Loading…
        </div>
      );
    }

    const { billAddress } = this.props;

    const name = `${billAddress.firstname} ${billAddress.lastname}`;

    return (
      <form
        ref={this.node}
        className="CheckoutPaymentForm"
        method="post"
        action={this.props.action}
        onSubmit={this.handleSubmit}
      >
        <FormRailsHiddenInputs method="patch" />

        <div className="payment-card-fields-container">
          <div className="payment-card-fields">
            {this.renderErrors()}

            <div className="form-group">
              <div className="control-container">
                <label className="control-label">Card Number</label>

                <div className="control-input">
                  <input
                    disabled={isSubmitting}
                    type="text"
                    size={20}
                    maxLength={20}
                    className="form-control"
                    data-stripe="number"
                  />
                </div>

                <label className="control-label">CVC</label>

                <div className="control-input">
                  <input
                    disabled={isSubmitting}
                    type="text"
                    size={4}
                    maxLength={4}
                    className="form-control"
                    data-stripe="cvc"
                  />
                </div>
              </div>
            </div>

            <div className="form-group expiration-form-group">
              <div className="control-container">
                <label className="control-label">Expiration</label>

                <div className="control-input">
                  <CoreSelect disabled={isSubmitting} data-stripe="exp-month">
                    {this.renderMonths()}
                  </CoreSelect>

                  <CoreSelect disabled={isSubmitting} data-stripe="exp-year">
                    {this.renderYears()}
                  </CoreSelect>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="promotion-fields-container">
          <div className="promotion-fields">
            <div className="form-group">
              <div className="control-container">
                <label className="control-label">Coupon Code</label>
                <div className="control-input">
                  <input type="text" name="order[coupon_code]" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.renderHiddenInputs()}

        <input type="hidden" data-stripe="name" value={name} />

        <input
          type="hidden"
          data-stripe="address_line1"
          value={billAddress.address1}
        />

        <input
          type="hidden"
          data-stripe="address_line2"
          value={billAddress.address2 || ''}
        />

        <input
          type="hidden"
          data-stripe="address_city"
          value={billAddress.city}
        />

        <input
          type="hidden"
          data-stripe="address_state"
          value={billAddress.state}
        />

        <input
          type="hidden"
          data-stripe="address_zip"
          value={billAddress.zipcode}
        />

        <input
          type="hidden"
          data-stripe="address_country"
          value={billAddress.country}
        />

        <div className="checkout-actions-container">
          <div className="checkout-actions">
            <button type="submit" disabled={isSubmitting}>
              Continue
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default PaymentForm;
