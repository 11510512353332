import React from 'react';
import CarouselContainer from 'components/core/carousel/container';
import CarouselItems from 'components/core/carousel/items';
import { FontSpecimen } from 'types';

interface Props {
  specimens: FontSpecimen[];
}

const FontFamilySpecimens: React.FC<Props> = (props) => {
  const { specimens } = props;

  const items = specimens.map((specimen, index) => {
    return (
      <div key={index} className="FontSpecimens-specimen-container">
        <img src={specimen.assetURL} className="FontSpecimens-specimen" />
        <h3 className="FontSpecimens-heading">{specimen.title}</h3>
      </div>
    );
  });

  return (
    <div className="FontSpecimens">
      <CarouselContainer itemCount={specimens.length}>
        <CarouselItems>{items}</CarouselItems>
      </CarouselContainer>
    </div>
  );
};

export default FontFamilySpecimens;
