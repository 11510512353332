import { useState, useEffect } from 'react';
import $script from 'scriptjs';

/**
 * Asynchronously loads JavaScript and returns `true` when the script is loaded.
 */
const useScript = (path: string): boolean => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    $script(path, () => {
      setLoaded(true);
    });
  }, []);

  return loaded;
};

export default useScript;
