import React from 'react';
import { connect } from 'react-redux';

import provideStoreToComponent from 'app-provider';
import { totalOrderQuantity } from 'modules/current-order/getters';
import GlobalState from 'GlobalState';

interface Props {
  totalOrderQuantity: number;
}

const CartLink = (props: Props) => {
  const itemsCount = props.totalOrderQuantity;
  let itemsCountEl = null;

  if (itemsCount !== 0) {
    itemsCountEl = <span className="CartLink-item-count">{itemsCount}</span>;
  }

  return (
    <a className="CartLink" href="/cart">
      <span className="CartLink-icon" />
      {itemsCountEl}
    </a>
  );
};

export default provideStoreToComponent(
  connect((state: GlobalState) => {
    return {
      totalOrderQuantity: totalOrderQuantity(state)
    };
  })(CartLink)
);
