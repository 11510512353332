import React from 'react';
import { connect } from 'react-redux';
import provideStoreToComponent from 'app-provider';
import { toggleNavigationVisibility } from 'modules/options/action-creators';

interface Props {
  onClick: React.ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
}

const NavigationButton: React.FC<Props> = (props) => {
  const { onClick } = props;

  return (
    <div className="NavigationButton-container">
      <button
        type="button"
        className="NavigationButton burger-toggle navbar-toggle collapsed toggle-btn"
        onClick={onClick}
      >
        <span className="sr-only">Toggle navigation</span>
        <span className="icon-bar top-bar"></span>
        <span className="icon-bar middle-bar"></span>
        <span className="icon-bar bottom-bar"></span>
      </button>

      <span>MENU</span>
    </div>
  );
};

export default provideStoreToComponent(
  connect(null, (dispatch) => {
    return {
      onClick() {
        dispatch(toggleNavigationVisibility());
      }
    };
  })(NavigationButton)
);
