import $ from 'jquery';

import store from 'store';

function searchIsVisibleChanged(isVisible: boolean) {
  $('body').toggleClass('search-visible', isVisible);
}

function navigationIsVisibleChanged(isVisible: boolean) {
  $('body').toggleClass('mobile-navigation-visible', isVisible);
}

function storeChanged() {
  const { options } = store.getState();

  searchIsVisibleChanged(options.searchIsVisible);
  navigationIsVisibleChanged(options.navigationIsVisible);
}

$(document).ready(storeChanged);

store.subscribe(storeChanged);
