import React, { createRef } from 'react';
import $ from 'jquery';
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';
import classNames from 'classnames';

import ProductsStoreItem from './store-item';
import { StoreItem } from 'types';

export interface Props {
  items: StoreItem[];
  imageSize: 'small' | 'medium' | 'large';
  onItemsLayout?(): void;
}

class StoreItems extends React.Component<Props> {
  static defaultProps = {
    items: []
  };

  node = createRef<HTMLDivElement>();
  masonry?: Masonry;
  stopResizingTimeout?: number;

  state = {
    isResizing: false
  };

  componentDidMount() {
    if (this.node.current != null) {
      this.masonry = new Masonry(this.node.current, {
        transitionDuration: 0
      });
    }

    this.layoutItems();

    $(window).on({
      resize: this.handleResize
    });
  }

  componentWillUnmount() {
    if (this.masonry != null && this.masonry.destroy != null) {
      this.masonry.destroy();
    }

    $(window).off({
      resize: this.handleResize
    });
  }

  componentDidUpdate() {
    if (this.masonry != null && this.masonry.reloadItems != null) {
      this.masonry.reloadItems();
    }

    this.layoutItems();
  }

  handleResize = () => {
    clearTimeout(this.stopResizingTimeout);

    this.setState({
      isResizing: true
    });

    this.stopResizingTimeout = window.setTimeout(() => {
      this.setState({
        isResizing: false
      });
    }, 100);
  };

  layoutItems() {
    if (this.node.current == null) {
      return;
    }

    imagesLoaded(this.node.current, () => {
      if (this.masonry != null && this.masonry.layout != null) {
        this.masonry.layout();
      }

      if (this.props.onItemsLayout != null) {
        this.props.onItemsLayout();
      }
    });
  }

  render() {
    const { imageSize, items } = this.props;
    const { isResizing } = this.state;

    const classes = classNames('StoreItems', `StoreItems--${imageSize}`, {
      'StoreItems--resizing': isResizing
    });

    return (
      <div className={classes} ref={this.node}>
        {items.map((item) => {
          return <ProductsStoreItem key={item.id} item={item} />;
        })}
      </div>
    );
  }
}

export default StoreItems;
