import { createContext } from 'react';

const CarouselContext = createContext<{
  itemCount: number;
  itemIndex: number;
  previousItem(): void;
  nextItem(): void;
  selectItem(index: number): void;
}>({
  itemCount: 0,
  itemIndex: 0,
  previousItem: () => {},
  nextItem: () => {},
  selectItem: () => {}
});

export default CarouselContext;
