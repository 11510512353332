import React, { createRef } from 'react';
import HomePageItem from './item';
import classNames from 'classnames';
import $ from 'jquery';
import { HomePageSection } from 'types';

interface Props {
  section: HomePageSection;
  parentAnimating: boolean;
  rotationAnimationInterval: number;
  rotationAnimationOffset: number;
  onVideoPlay?(): void;
  onVideoPause?(): void;
}

/**
 * A carousel of image + text items which is rendered as one block on the home page.
 */
class HomePageFeaturesSection extends React.Component<Props> {
  static defaultProps = {
    parentAnimating: true,
    rotationAnimationInterval: 2500,
    // Offset of 'phase' of the carousel cycling animation. This offset is in relation to
    // the component mounting, so it's only useful to offset animations of sections rendered
    // together.
    rotationAnimationOffset: 0
  };

  state = {
    index: 0,
    mouseOver: false,
    height: 0
  };

  node = createRef<HTMLDivElement>();
  timeId?: number;

  /**
   * Animation can be controlled at two levels. This component will pause it's animation
   * on mouse over, or the parent can pause animation.
   */
  animating() {
    return this.props.parentAnimating && !this.state.mouseOver;
  }

  stepForward() {
    if (!this.animating()) {
      return;
    }
    const count = this.props.section.home_page_items.length;

    if (count <= 1) {
      return;
    }

    if (this.node.current == null) {
      return;
    }

    const nextIndex = (this.state.index + 1) % count;
    const height = $(this.node.current).height();
    const heightToSet = height === 0 ? this.state.height : height;

    this.setState({
      index: nextIndex,
      height: heightToSet
    });
  }

  updateSize = () => {
    this.setState({
      height: -1
    });
  };

  componentDidMount() {
    this.timeId = window.setTimeout(
      this.startAnimation,
      this.props.rotationAnimationOffset
    );

    window.addEventListener('resize', this.updateSize);
  }

  isAnimatingSection() {
    const { home_page_section_type, home_page_items } = this.props.section;

    return (
      home_page_section_type != null &&
      home_page_section_type.name === 'large' &&
      home_page_items.length > 1
    );
  }

  startAnimation = () => {
    if (this.isAnimatingSection()) {
      this.timeId = window.setInterval(
        this.stepForward.bind(this),
        this.props.rotationAnimationInterval
      );
    }
  };

  onMouseEnter = () => {
    this.setState({ mouseOver: true });
  };

  onMouseLeave = () => {
    this.setState({ mouseOver: false });
  };

  renderEmpty() {
    return <div className="HomePageFeaturesSection" />;
  }

  render() {
    const { section, onVideoPlay, onVideoPause } = this.props;

    if (
      section == null ||
      section.home_page_section_type == null ||
      section.home_page_items.length === 0
    ) {
      return this.renderEmpty();
    }

    const items = section.home_page_items.map((item, index) => {
      if (section.home_page_section_type == null) {
        return null;
      }

      const classes = classNames({
        'HomePageFeaturesItem--current': this.state.index === index
      });

      return (
        <HomePageItem
          key={item.id}
          isCurrent={this.state.index === index}
          className={classes}
          item={item}
          onVideoPlay={onVideoPlay}
          onVideoPause={onVideoPause}
          sectionType={section.home_page_section_type.name}
        />
      );
    });

    let style = {};

    if (this.isAnimatingSection() && this.state.height > 0) {
      style = {
        height: this.state.height + 'px'
      };
    }

    if (this.state.height === -1) {
      style = {
        height: 'auto'
      };
    }

    return (
      <div
        ref={this.node}
        className="home-page-section"
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        style={style}
      >
        {items}
      </div>
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeId);
    window.removeEventListener('resize', this.updateSize);
  }
}

export default HomePageFeaturesSection;
