import React from 'react';

import ProductOptions from 'components/product/options';
import ProductRestockNotificationForm from 'components/product/restock-notification-form';

import { connect } from 'react-redux';
import provideStoreToComponent from 'app-provider';

import { currentProduct, selectedVariant } from 'modules/product/getters';

import { addLineItemStart } from 'modules/current-order/action-creators';

import { Variant } from 'types';
import GlobalState from 'GlobalState';

interface Props {
  currentProduct: GlobalState['product'];
  selectedVariant?: Variant;
  addLineItem(values: {
    productId: number;
    variantId: number;
    variantPrice: number;
    quantity: number;
  }): void;
}

interface State {
  quantity: number | null;
}

class ProductCart extends React.Component<Props, State> {
  state = {
    quantity: 1
  };

  addToCart = () => {
    const { currentProduct, selectedVariant, addLineItem } = this.props;
    const { quantity } = this.state;

    if (currentProduct == null) {
      throw new Error('Missing current product.');
    }

    const variantId = selectedVariant
      ? selectedVariant.id
      : currentProduct.master_variant_id;

    const variantPrice = selectedVariant
      ? selectedVariant.price
      : currentProduct.price;

    addLineItem({
      productId: currentProduct.id,
      variantId,
      variantPrice,
      quantity
    });
  };

  get selectedProductInStock() {
    const { currentProduct, selectedVariant } = this.props;

    if (selectedVariant) {
      return selectedVariant.in_stock;
    } else {
      return (
        currentProduct &&
        currentProduct.variants.length === 0 &&
        currentProduct.in_stock
      );
    }
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      quantity: parseInt(event.currentTarget.value, 10) || null
    });
  };

  handleInputBlur = () => {
    if (!this.state.quantity || this.state.quantity < 1) {
      this.setState({ quantity: 1 });
    }
  };

  renderQuantity() {
    if (this.selectedProductInStock) {
      return (
        <div className="ProductCart-quantity">
          <label>QTY</label>
          <input
            type="number"
            pattern="[0-9]*"
            value={this.state.quantity || ''}
            onChange={this.handleInputChange}
            onBlur={this.handleInputBlur}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  renderAddToCart() {
    const { currentProduct, selectedVariant } = this.props;

    if (currentProduct == null) {
      throw new Error('Missing current product.');
    }

    if (this.selectedProductInStock) {
      return (
        <button className="product-add-to-cart" onClick={this.addToCart}>
          Add To Bag
        </button>
      );
    }

    if (selectedVariant) {
      return <ProductRestockNotificationForm variantId={selectedVariant.id} />;
    } else {
      return (
        <ProductRestockNotificationForm
          variantId={currentProduct.master_variant_id}
        />
      );
    }
  }

  render() {
    const { currentProduct, selectedVariant } = this.props;

    if (currentProduct) {
      return (
        <div>
          <ProductOptions
            variants={currentProduct.variants}
            optionTypes={currentProduct.options}
            selectedVariant={selectedVariant}
          />
          {this.renderQuantity()}
          {this.renderAddToCart()}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default provideStoreToComponent(
  connect(
    (state: GlobalState) => {
      return {
        selectedVariant: selectedVariant(state),
        currentProduct: currentProduct(state)
      };
    },
    (dispatch) => {
      return {
        addLineItem(values: {
          productId: number;
          variantId: number;
          variantPrice: number;
          quantity: number;
        }) {
          dispatch(addLineItemStart(values));
        }
      };
    }
  )(ProductCart)
);
