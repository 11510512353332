import React from 'react';
import numeral from 'numeral';

import { connect } from 'react-redux';
import provideStoreToComponent from 'app-provider';
import ProductImage from 'components/product/image';

import {
  removeLineItemStart,
  updateLineItemStart
} from 'modules/current-order/action-creators';

import GlobalState from 'GlobalState';
import { LineItem } from 'types';

interface Props {
  currentOrderNumber: string;
  lineItem: LineItem;
  editable?: boolean;
  removeLineItem(lineItemId: number, orderNumber: string): void;
  updateLineItem(
    lineItemId: number,
    quantity: number,
    orderNumber: string
  ): void;
}

interface State {
  quantity: string;
}

class CartLineItem extends React.Component<Props, State> {
  static defaultProps = {
    editable: true
  };

  state: State = { quantity: this.props.lineItem.quantity.toString() };

  get quantity(): number {
    return parseInt(this.state.quantity) || 0;
  }

  componentDidUpdate(prevProps: Props) {
    const { lineItem } = this.props;

    if (prevProps.lineItem.quantity !== lineItem.quantity) {
      this.setState({
        quantity: lineItem.quantity.toString()
      });
    }
  }

  updateQuantity = () => {
    const { updateLineItem, removeLineItem, currentOrderNumber } = this.props;

    if (this.quantity !== this.props.lineItem.quantity) {
      if (this.quantity <= 0) {
        removeLineItem(this.props.lineItem.id, currentOrderNumber);
      } else {
        updateLineItem(
          this.props.lineItem.id,
          this.quantity,
          currentOrderNumber
        );
      }
    }
  };

  remove = () => {
    const { removeLineItem, lineItem, currentOrderNumber } = this.props;

    removeLineItem(lineItem.id, currentOrderNumber);
  };

  renderImage() {
    const { lineItem } = this.props;

    if ('preview' in lineItem.image_urls) {
      return (
        <span className="ProductImage">
          <img src={lineItem.image_urls.preview} />
        </span>
      );
    } else {
      return <ProductImage urls={lineItem.image_urls} selectedUrl="small" />;
    }
  }

  renderFontLicenseOptions() {
    const { lineItem } = this.props;

    if (lineItem.font_license_type) {
      return `(${lineItem.font_license_description})`;
    } else {
      return null;
    }
  }

  renderFontLicenseLink(className: string) {
    const { editable, lineItem } = this.props;

    if (editable && lineItem.license_style_ids) {
      const queryLink = `${lineItem.product.url}?show_licensing=1`;

      return (
        <div className={className}>
          <a className="CartLineItem-add-license" href={queryLink}>
            Add More
          </a>
        </div>
      );
    } else {
      return null;
    }
  }

  renderQuantity() {
    const { lineItem, editable } = this.props;

    if (lineItem.font_license_type) {
      return null;
    }

    if (editable) {
      return (
        <div className="CartLineItem-quantity">
          <label>Qty</label>
          <input
            type="number"
            pattern="[0-9]*"
            value={this.state.quantity}
            onChange={(event) => {
              this.setState({
                quantity: event.currentTarget.value
              });
            }}
            onBlur={this.updateQuantity}
            onKeyDown={(event) => {
              if (event.keyCode === 13) {
                event.currentTarget.blur();
              }
            }}
          />
        </div>
      );
    } else {
      return (
        <div className="CartLineItem-quantity">Qty {lineItem.quantity}</div>
      );
    }
  }

  renderActions() {
    if (!this.props.editable) {
      return null;
    }

    return (
      <div className="CartLineItem-actions">
        <button className="CartLineItem-remove-action" onClick={this.remove} />
      </div>
    );
  }

  render() {
    const { lineItem } = this.props;
    const href = lineItem.product.url;

    const optionsText = lineItem.options_text.map((text, index) => {
      const prefix = lineItem.font_license_type == null ? '' : 'Styles: ';
      return (
        <div key={index}>
          {prefix}
          {text}
        </div>
      );
    });

    const total = numeral(lineItem.total).format('$0,0.00');

    return (
      <div className="CartLineItem">
        <div className="CartLineItem-inside">
          <div className="CartLineItem-image">{this.renderImage()}</div>

          <div className="CartLineItem-details">
            <a className="CartLineItem-name" href={href}>
              {lineItem.product.name} {this.renderFontLicenseOptions()}
            </a>

            <div className="CartLineItem-options">{optionsText}</div>
            <div className="CartLineItem-mobile-price">{total}</div>

            {this.renderFontLicenseLink('CartLineItem-mobile-add-license')}
          </div>

          {this.renderFontLicenseLink('CartLineItem-wide-add-license')}

          {this.renderQuantity()}
          <div className="CartLineItem-price">{total}</div>
          {this.renderActions()}
        </div>
      </div>
    );
  }
}

export default provideStoreToComponent(
  connect(
    (state: GlobalState) => {
      return {
        currentOrderNumber:
          state.currentOrder != null ? state.currentOrder.number : null
      };
    },
    (dispatch) => {
      return {
        removeLineItem(lineItemId: number, orderNumber: string) {
          dispatch(removeLineItemStart(lineItemId, orderNumber));
        },
        updateLineItem(
          lineItemId: number,
          quantity: number,
          orderNumber: string
        ) {
          dispatch(updateLineItemStart(lineItemId, quantity, orderNumber));
        }
      };
    }
  )(CartLineItem)
);
