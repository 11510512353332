import React, { createRef } from 'react';
import $ from 'jquery';
import classNames from 'classnames';
import { Motion, spring, OpaqueConfig } from 'react-motion';
import { throttle } from 'lodash';

import Context from './context';
import ItemMap from './item-map';

interface State {
  translateX: number | null;
}

export default class CarouselItems extends React.PureComponent<{}, State> {
  initialTouchX: number | null = null;
  lastTouchX: number | null = null;

  state: State = {
    translateX: null
  };

  node = createRef<HTMLDivElement>();

  deltaTouchX() {
    const { initialTouchX, lastTouchX } = this;

    const componentWidth =
      this.node.current != null ? $(this.node.current).outerWidth() : null;

    if (
      componentWidth == null ||
      initialTouchX === null ||
      lastTouchX === null
    ) {
      return 0;
    }

    return Math.abs(lastTouchX - initialTouchX) / componentWidth;
  }

  render() {
    return (
      <Context.Consumer>
        {({ itemCount, itemIndex, previousItem, nextItem, selectItem }) => {
          const scaleIndex = (index: number) => {
            return (-100 / itemCount) * index;
          };

          const itemsStyle = (x: number) => {
            const transform = `translateX(${x}%)`;

            return {
              width: `${itemCount * 100}%`,
              WebkitTransform: transform,
              msTransform: transform,
              transform
            };
          };

          const updateTranslateX = throttle(() => {
            const itemWidth = 100 / itemCount;
            const { initialTouchX, lastTouchX } = this;
            const deltaTouchX = this.deltaTouchX();
            const offsetX = itemIndex * (100 / itemCount);

            let translateX;

            if (lastTouchX == null || initialTouchX == null) {
              return;
            }

            if (lastTouchX > initialTouchX) {
              translateX = deltaTouchX * itemWidth - offsetX;
            } else {
              translateX = deltaTouchX * -itemWidth - offsetX;
            }

            this.setState({ translateX });
          }, 2);

          const onTouchStart = (event: React.TouchEvent) => {
            this.initialTouchX = event.touches[0].clientX;
          };

          const onTouchMove = (event: React.TouchEvent) => {
            this.lastTouchX = event.touches[0].clientX;

            updateTranslateX();
          };

          const onTouchEnd = () => {
            const { initialTouchX, lastTouchX } = this;

            if (initialTouchX == null) {
              return;
            }

            if (lastTouchX && this.deltaTouchX() > 0.35) {
              let index;

              if (lastTouchX > initialTouchX) {
                index = itemIndex - 1;
              } else {
                index = itemIndex + 1;
              }

              selectItem(index);
            }

            this.setState({ translateX: null });

            this.initialTouchX = null;
            this.lastTouchX = null;
          };

          const defaultStyle = {
            x: scaleIndex(0)
          };

          let style: { x: number | OpaqueConfig };

          const { translateX } = this.state;

          if (translateX == null) {
            style = {
              x: spring(scaleIndex(itemIndex), {
                stiffness: 120,
                damping: 21
              })
            };
          } else {
            style = {
              x: translateX
            };
          }

          return (
            <div
              ref={this.node}
              className={classNames('CarouselItems', {
                'CarouselItems--single-item': itemCount === 1
              })}
              onTouchStart={onTouchStart}
              onTouchMove={onTouchMove}
              onTouchEnd={onTouchEnd}
            >
              <Motion defaultStyle={defaultStyle} style={style}>
                {(value) => {
                  return (
                    <div
                      className="CarouselItems-container"
                      style={itemsStyle(value.x)}
                    >
                      {React.Children.map(
                        this.props.children,
                        (child, index) => {
                          return (
                            <div
                              key={index}
                              className="CarouselItems-item"
                              style={{ width: `${100 / itemCount}%` }}
                            >
                              {child}
                            </div>
                          );
                        }
                      )}
                    </div>
                  );
                }}
              </Motion>

              <ItemMap />

              {itemCount > 1 && (
                <button
                  className="CarouselItems-arrow-left"
                  onClick={previousItem}
                />
              )}

              {itemCount > 1 && (
                <button
                  className="CarouselItems-arrow-right"
                  onClick={nextItem}
                />
              )}
            </div>
          );
        }}
      </Context.Consumer>
    );
  }
}
