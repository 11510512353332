import React, { forwardRef } from 'react';

import AddToBagButton from './add-to-bag-button';
import TotalPrice from './total-price';

interface Props {
  name: string;
  onClose(): void;
}

const SectionHeader = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { name, onClose } = props;

  return (
    <div ref={ref} className="FontLicensingSection-header">
      <button className="FontLicensingSection-close" onClick={onClose} />

      <div className="FontLicensingSection-name">
        <div className="FontLicensingSection-name-value">{name}</div>
      </div>

      <TotalPrice />

      <AddToBagButton />
    </div>
  );
});

export default SectionHeader;
