import React, { createRef } from 'react';
import classNames from 'classnames';
import { throttle, isEmpty } from 'lodash';

import $ from 'jquery';
import 'jquery-smooth-scroll';

import { connect } from 'react-redux';
import provideStoreToComponent from 'app-provider';

import globalData from 'utils/global-data';

import { toggleLicenseSection } from 'modules/font-licensing/action-creators';

import PageNav from './page-nav';
import TypeTestersSection from './type-testers-section';
import SpecimensSection from './specimens-section';
import AboutSection from './about-section';
import ExtrasSection from './extras-section';
import GlyphsSection from './glyphs-section';
import FeaturesSection from './features-section';
import LicensingSection from 'components/font-licensing/section';
import Declarations from './declarations';

import { FontFamily } from 'types';
import GlobalState from 'GlobalState';

function sectionIsVisible(fontFamily: FontFamily, sectionName: string) {
  switch (sectionName) {
    case 'testers':
      return fontFamily.type_testers.length > 0;
    case 'specimens':
      return fontFamily.specimens.length > 0;
    case 'about':
      return !isEmpty(fontFamily.description);
    case 'extras':
      return fontFamily.ornaments.length > 0;
    case 'glyphs':
      return fontFamily.styles.length > 0;
    case 'features':
      return fontFamily.features.length > 0;
    default:
      return false;
  }
}

const SECTION_NAMES = [
  'testers',
  'specimens',
  'about',
  'extras',
  'glyphs',
  'features'
];

const MAIN_NAV_HEIGHT = 40;
const SCROLL_THROTTLE_DELAY = 20;
const SECTION_SCROLL_OFFSET = -150;

interface Props {
  fontFamily: FontFamily;
  licensingIsVisible: boolean;
  toggleLicenseSection(isVisible: boolean): void;
}

class FontFamilyPage extends React.Component<Props> {
  static defaultProps = {
    fontFamily: globalData().fontFamily
  };

  state = {
    activeSection: 'testers',
    navIsFixed: false
  };

  headerNode = createRef<HTMLDivElement>();

  sectionNodes = {
    testers: createRef<HTMLDivElement>(),
    specimens: createRef<HTMLDivElement>(),
    about: createRef<HTMLDivElement>(),
    extras: createRef<HTMLDivElement>(),
    glyphs: createRef<HTMLDivElement>(),
    features: createRef<HTMLDivElement>()
  };

  componentDidMount() {
    if (globalData().showLicensing) {
      this.toggleLicensing();
    }

    this.addScrollListener();
  }

  componentWillUnmount() {
    this.removeScrollListener();
  }

  addScrollListener() {
    $(window).on('scroll', this.onScrollEvent);
  }

  removeScrollListener() {
    $(window).off('scroll', this.onScrollEvent);
  }

  onScrollEvent = throttle(() => {
    this.updateActiveSection();
    this.updateFixedNav();
  }, SCROLL_THROTTLE_DELAY);

  updateActiveSection() {
    if (this.headerNode.current == null) {
      return;
    }

    const headerBounds = this.headerNode.current.getBoundingClientRect();
    const headerBottom = headerBounds.bottom + window.scrollY;

    const activeSection =
      SECTION_NAMES.find((sectionName) => {
        const element: HTMLDivElement = this.sectionNodes[sectionName].current;

        if (element == null) {
          return false;
        }

        const offset = $(element).offset();
        const outerHeight = $(element).outerHeight();

        if (offset == null || outerHeight == null) {
          return false;
        }

        const top = offset.top;
        const bottom = top + outerHeight;

        return top <= headerBottom && bottom > headerBottom;
      }) || null;

    this.setState({ activeSection });
  }

  updateFixedNav() {
    const navIsFixed = window.scrollY > MAIN_NAV_HEIGHT;

    this.setState({ navIsFixed });
  }

  scrollToSection = (sectionName: string) => {
    const element = this.sectionNodes[sectionName].current;

    this.removeScrollListener();

    // @ts-ignore
    $.smoothScroll({
      scrollTarget: element,
      offset: SECTION_SCROLL_OFFSET,
      afterScroll: () => {
        this.updateActiveSection();
        this.addScrollListener();
      }
    });
  };

  toggleLicensing = () => {
    const { licensingIsVisible, toggleLicenseSection } = this.props;

    return toggleLicenseSection(!licensingIsVisible);
  };

  renderLicensingSection() {
    const { licensingIsVisible, fontFamily } = this.props;

    if (licensingIsVisible) {
      const { styles, name } = fontFamily;
      return <LicensingSection styles={styles} name={name} />;
    }

    return null;
  }

  render() {
    const { fontFamily } = this.props;
    const { navIsFixed, activeSection } = this.state;

    const {
      name,
      type_testers,
      specimens,
      description,
      ornaments,
      styles,
      features
    } = fontFamily;

    const navProps = {
      activeSection,
      sectionNames: SECTION_NAMES,
      scrollToSection: this.scrollToSection,
      sectionIsVisible: sectionIsVisible.bind(null, fontFamily)
    };

    const classes = classNames('font-family-page', {
      'fixed-navigation': navIsFixed
    });

    return (
      <div className={classes}>
        <div className="font-family-page-content">
          <div className="font-header" ref={this.headerNode}>
            <div className="font-header-font-name">
              <h1>{name}</h1>

              <button
                className="toggle-licensing-button"
                onClick={this.toggleLicensing}
              >
                Buying Options
              </button>
            </div>

            <PageNav {...navProps} />
          </div>

          <TypeTestersSection
            ref={this.sectionNodes.testers}
            typeTesters={type_testers}
            isVisible={sectionIsVisible(fontFamily, 'testers')}
          />

          <SpecimensSection
            ref={this.sectionNodes.specimens}
            specimens={specimens}
            isVisible={sectionIsVisible(fontFamily, 'specimens')}
          />

          <AboutSection
            ref={this.sectionNodes.about}
            description={description}
            isVisible={sectionIsVisible(fontFamily, 'about')}
          />

          <ExtrasSection
            ref={this.sectionNodes.extras}
            ornaments={ornaments}
            isVisible={sectionIsVisible(fontFamily, 'extras')}
          />

          <GlyphsSection
            ref={this.sectionNodes.glyphs}
            styles={styles}
            isVisible={sectionIsVisible(fontFamily, 'glyphs')}
          />

          <FeaturesSection
            ref={this.sectionNodes.features}
            features={features}
            isVisible={sectionIsVisible(fontFamily, 'features')}
          />

          {this.renderLicensingSection()}

          <Declarations />
        </div>
      </div>
    );
  }
}

export default provideStoreToComponent(
  connect(
    (state: GlobalState) => {
      return {
        licensingIsVisible: state.fontLicensing.isVisible
      };
    },
    (dispatch) => {
      return {
        toggleLicenseSection(isVisible: boolean) {
          dispatch(toggleLicenseSection(isVisible));
        }
      };
    }
  )(FontFamilyPage)
);
