import React from 'react';

import Licenses from 'components/font-licensing/licenses';

const Actions: React.FC<{ cartRoute: string }> = (props) => {
  return (
    <div className="license-agreement-actions">
      <a href={props.cartRoute}>I Do Not Agree</a>
      <input type="submit" value="I Agree to the Terms" />
    </div>
  );
};

interface Props {
  routes: {
    cart: string;
  };
  orderLicenseTypes: string[];
  licenseText: {
    desktop: string;
    web: string;
    app: string;
  };
}

const CheckoutLicenseAgreement: React.FC<Props> = (props) => {
  return (
    <div>
      <h1>Font Licenses</h1>

      <Actions cartRoute={props.routes.cart} />

      <Licenses
        visibleLicenseTypes={props.orderLicenseTypes}
        licenseText={props.licenseText}
      />

      <Actions cartRoute={props.routes.cart} />
    </div>
  );
};

export default CheckoutLicenseAgreement;
