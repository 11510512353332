import React from 'react';
import TetherComponent from 'react-tether';

interface State {
  visible: boolean;
}

class Tooltip extends React.PureComponent<{}, State> {
  state = {
    visible: false
  };

  show = () => {
    this.setState({ visible: true });
  };

  hide = () => {
    this.setState({ visible: false });
  };

  render() {
    const { children } = this.props;
    const { visible } = this.state;

    return (
      <TetherComponent
        attachment="top center"
        targetAttachment="bottom center"
        constraints={[
          {
            to: 'window',
            attachment: 'together'
          }
        ]}
        renderElement={() => (
          <>
            <span
              className="TooltipAnchor"
              onMouseOver={this.show}
              onMouseOut={this.hide}
            >
              ?
            </span>

            {visible && <div className="TooltipContent">{children}</div>}
          </>
        )}
      />
    );
  }
}

export default Tooltip;
