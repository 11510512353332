import React, { createRef } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { get } from 'lodash';

import globalData from 'utils/global-data';
import provideStoreToComponent from 'app-provider';
import { pushItems, resetOffset } from 'modules/products/action-creators';
import StoreItems from './store-items';
import GlobalState from 'GlobalState';
import { StoreItem } from 'types';

interface Props {
  offset: number;
  items: StoreItem[];
  imageSize: 'small' | 'medium' | 'large';
  selectedTaxonId: number;
  keywords: string;
  limit: number;
  pushItems(storeItems: StoreItem[]): void;
  resetOffset(): void;
}

class PaginatedStoreItems extends React.PureComponent<Props> {
  static defaultProps = {
    limit: get(globalData(), 'storeItemsLimit', 14)
  };

  state = {
    isLoading: false,
    isLoaded: false
  };

  node = createRef<HTMLDivElement>();

  componentDidUpdate(prevProps: Props) {
    if (this.props.imageSize !== prevProps.imageSize) {
      this.props.resetOffset();

      window.scrollTo(0, 0);

      this.setState({
        isLoaded: false
      });
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const scrollPosY = window.pageYOffset + window.innerHeight;
    const documentHeight = document.body.getBoundingClientRect().height;

    if (scrollPosY > documentHeight - 100) {
      this.loadMoreItems();
    }
  };

  itemsDidLayout = () => {
    if (this.shouldLoadMoreItems()) {
      this.loadMoreItems();
    }
  };

  loadMoreItems() {
    if (this.state.isLoaded || this.state.isLoading) {
      return;
    }

    this.setState({
      isLoading: true
    });

    const { selectedTaxonId, pushItems, keywords, offset, limit } = this.props;

    $.getJSON('/items', {
      taxon_id: selectedTaxonId,
      keywords,
      offset
    }).then((data) => {
      this.setState({
        isLoading: false
      });

      if (data.items.length < limit) {
        this.setState({
          isLoaded: true
        });
      }

      pushItems(data.items as StoreItem[]);
    });
  }

  shouldLoadMoreItems() {
    const { isLoaded } = this.state;

    return (
      !isLoaded &&
      this.node.current != null &&
      this.node.current.getBoundingClientRect().height < window.innerHeight
    );
  }

  render() {
    const { imageSize, items } = this.props;
    const { isLoading } = this.state;

    const classes = classNames(
      'PaginatedStoreItems',
      `PaginatedStoreItems--${imageSize}`,
      {
        'PaginatedStoreItems--loading': isLoading
      }
    );

    return (
      <div className={classes} ref={this.node}>
        <StoreItems
          items={items}
          imageSize={imageSize}
          onItemsLayout={this.itemsDidLayout}
        />
      </div>
    );
  }
}

export default provideStoreToComponent(
  connect(
    (state: GlobalState) => {
      return {
        imageSize: state.products.imageSize,
        items: state.products.items,
        offset: state.products.offset,
        selectedTaxonId: state.products.selectedTaxonId
      };
    },
    (dispatch) => {
      return {
        pushItems(items: StoreItem[]) {
          dispatch(pushItems(items));
        },
        resetOffset() {
          dispatch(resetOffset());
        }
      };
    }
  )(PaginatedStoreItems)
);
