import React from 'react';

import TaxonNavigationGroup from './taxon-navigation-group';
import { Taxon } from 'types';

const TaxonNavigation: React.FC<{
  taxons: Taxon[];
}> = (props) => {
  const { taxons } = props;

  return (
    <div className="TaxonNavigation">
      {taxons.map((taxon) => (
        <TaxonNavigationGroup key={taxon.id} taxon={taxon} />
      ))}
    </div>
  );
};

export default TaxonNavigation;
