import React, { forwardRef } from 'react';

import { FontOrnament } from 'types';

interface Props {
  ornaments: FontOrnament[];
  isVisible: boolean;
}

const FontFamilyExtrasSection = forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { ornaments, isVisible } = props;

    if (isVisible) {
      return (
        <div ref={ref} className="extras">
          <h2>Extras</h2>

          {ornaments.map((ornament, index) => {
            return (
              <div key={index} className="extra">
                <img src={ornament.assetURL} />
                <h3>{ornament.title}</h3>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  }
);

export default FontFamilyExtrasSection;
