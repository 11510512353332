import React, { memo } from 'react';

import { useDeclarations } from 'modules/font-declarations';

const FontFamilyDeclarations: React.FC = () => {
  const declarations = useDeclarations();

  return (
    <>
      {declarations.map(({ id, declaration }) => (
        <style
          key={id}
          type="text/css"
          dangerouslySetInnerHTML={{ __html: declaration }}
        />
      ))}
    </>
  );
};

export default memo(FontFamilyDeclarations);
