import React from 'react';

import { find } from 'lodash';

interface Props {
  selectedResultId: number;
  product: {
    id: number;
    name: string;
    path: string;
  };
  highlightTerms: string[];
}

const SearchFormResult: React.FC<Props> = (props: Props) => {
  const { selectedResultId, product, highlightTerms } = props;

  const name = product.name
    .toLowerCase()
    .split(/\s+/)
    .map((nameWord, index) => {
      const highlightTerm = find(
        highlightTerms,
        (term) => nameWord.indexOf(term) > -1
      );

      if (highlightTerm != null) {
        const termIndex = nameWord.indexOf(highlightTerm);

        return (
          <span key={index}>
            <span>{nameWord.slice(0, termIndex)}</span>
            <b>{nameWord.slice(termIndex, termIndex + highlightTerm.length)}</b>
            <span>{nameWord.slice(termIndex + highlightTerm.length)} </span>
          </span>
        );
      } else {
        return <span key={index}>{nameWord} </span>;
      }
    });

  return (
    <li className={selectedResultId === product.id ? 'selected' : undefined}>
      <a href={product.path}>{name}</a>
    </li>
  );
};

export default SearchFormResult;
