import React from 'react';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';

import provideStoreToComponent from 'app-provider';
import HomePageFeaturesSection from './home-page-features/section';
import KlaviyoForm from './klaviyo-form';
import GlobalState from 'GlobalState';
import { User, HomePageSection } from 'types';

interface Props {
  homePageSections: object;
  user: User | null;
  klaviyoListId: string | null;
}

interface State {
  animating: boolean;
  showMailingListForm: boolean;
}

/**
 * A collection of featured items to be displayed on the home page.
 *
 * Exactly three sections are expected. The first is displayed more prominently.
 * Each section can have one or more items it cycles through, each with images or videos
 * and some text.
 */
class HomePageFeatures extends React.Component<Props, State> {
  state = {
    animating: true,
    showMailingListForm: this.shouldShowMailingListForm()
  };

  componentDidUpdate() {
    this.setState({
      showMailingListForm: this.shouldShowMailingListForm()
    });
  }

  handleVideoPlay = () => {
    this.setState({
      animating: false
    });
  };

  handleVideoPause = () => {
    this.setState({
      animating: true
    });
  };

  handleMailingListSuccess = () => {
    this.setState({
      showMailingListForm: this.shouldShowMailingListForm()
    });
  };

  shouldShowMailingListForm() {
    return (
      (this.props.user == null || this.props.user.receive_email === false) &&
      Cookies.get('hide_mailing_list_form') == null
    );
  }

  render() {
    const { klaviyoListId } = this.props;
    const sections = this.props.homePageSections;
    const sectionAttributes = {
      parentAnimating: this.state.animating,
      handleVideoPlay: this.handleVideoPlay,
      handleVideoPause: this.handleVideoPause
    };
    const sectionsArr = Object.values(sections);
    const firstRowSections = sectionsArr.slice(0, 2);
    const secondRowSections = sectionsArr.slice(2, 3);
    const secondRowSections2 = sectionsArr.slice(3, 4);

    return (
      <div>
        <div className="HomePageFeatures">
          {Object.values(sections).map((section) => {
            let classes = 'row ' + section.home_page_section_type.css_class;

            return (
              <div key={section.id} className={classes}>
                <HomePageFeaturesSection
                  section={section}
                  {...sectionAttributes}
                />
              </div>
            );
          })}

          {klaviyoListId != null && <KlaviyoForm listId={klaviyoListId} />}
        </div>

        <div className="HomePageFeatures big-size">
          <div className="first-row">
            {this.renderSection(firstRowSections, sectionAttributes)}
          </div>

          <div className="second-row">
            <div className="col-left">
              {this.renderSection(secondRowSections, sectionAttributes)}
              {this.state.showMailingListForm && klaviyoListId != null && (
                <KlaviyoForm listId={klaviyoListId} />
              )}
            </div>

            {this.renderSection(secondRowSections2, sectionAttributes)}
          </div>
        </div>
      </div>
    );
  }

  renderSection(sections: HomePageSection[], sectionAttributes: {}) {
    return sections.map((section) => {
      if (section.home_page_section_type == null) {
        return null;
      }

      let classes = 'row ' + section.home_page_section_type.css_class;

      return (
        <div key={section.id} className={classes}>
          <HomePageFeaturesSection section={section} {...sectionAttributes} />
        </div>
      );
    });
  }
}

export { HomePageFeatures };

export default provideStoreToComponent(
  connect((state: GlobalState) => {
    return {
      user: state.currentUser
    };
  })(HomePageFeatures)
);
