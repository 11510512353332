import React from 'react';

import Context from './context';

interface Props {
  itemCount: number;
  itemIndex?: number;
  onPreviousItem?(): void;
  onNextItem?(): void;
  onSelectItem?(index: number): void;
}

interface State {
  itemIndex: number;
}

export default class CarouselContainer extends React.PureComponent<
  Props,
  State
> {
  state = {
    itemIndex: this.props.itemIndex || 0
  };

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.itemIndex != null &&
      prevProps.itemIndex !== this.props.itemIndex
    ) {
      this.setState({ itemIndex: this.props.itemIndex });
    }
  }

  previousItem = () => {
    if (this.props.onPreviousItem != null) {
      this.props.onPreviousItem();
    } else {
      this.setState((state) => {
        if (state.itemIndex === 0) {
          return {
            ...state,
            itemIndex: this.props.itemCount - 1
          };
        } else {
          return {
            ...state,
            itemIndex: state.itemIndex - 1
          };
        }
      });
    }
  };

  nextItem = () => {
    const { itemCount, onNextItem } = this.props;

    if (onNextItem != null) {
      onNextItem();
    } else {
      this.setState((state) => {
        if (state.itemIndex === itemCount - 1) {
          return {
            ...state,
            itemIndex: 0
          };
        } else {
          return {
            ...state,
            itemIndex: state.itemIndex + 1
          };
        }
      });
    }
  };

  selectItem = (index: number) => {
    if (this.props.onSelectItem != null) {
      this.props.onSelectItem(index);
    } else if (index >= 0 && index < this.props.itemCount) {
      this.setState({ itemIndex: index });
    }
  };

  render() {
    const { itemCount, children } = this.props;
    const { itemIndex } = this.state;

    return (
      <Context.Provider
        value={{
          itemCount,
          itemIndex,
          nextItem: this.nextItem,
          previousItem: this.previousItem,
          selectItem: this.selectItem
        }}
      >
        <div className="CarouselContainer">{children}</div>
      </Context.Provider>
    );
  }
}
