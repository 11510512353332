import React from 'react';
import { isEmpty } from 'lodash';

import { connect } from 'react-redux';
import provideStoreToComponent from 'app-provider';

import { hideLicensingModal } from 'modules/font-licensing/action-creators';

import CoreModal from 'components/core/modal';
import GlobalState from 'GlobalState';

interface Props {
  modalOptions: {
    heading: string;
    content: string;
    size: 'small' | 'large';
  };
  hideLicensingModal(): void;
}

const FontLicensingModal = (props: Props) => {
  const { heading, content, size } = props.modalOptions;
  const { hideLicensingModal } = props;
  const isVisible = !isEmpty(content);

  return (
    <CoreModal
      isVisible={isVisible}
      size={size}
      heading={heading}
      onClose={hideLicensingModal}
    >
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </CoreModal>
  );
};

export default provideStoreToComponent(
  connect(
    (state: GlobalState) => {
      return {
        modalOptions: state.fontLicensing.modalOptions
      };
    },
    (dispatch) => {
      return {
        hideLicensingModal() {
          dispatch(hideLicensingModal());
        }
      };
    }
  )(FontLicensingModal)
);
