import React from 'react';
import { connect } from 'react-redux';
import provideStoreToComponent from 'app-provider';
import globalData from 'utils/global-data';

import {
  selectCarouselItem,
  nextCarouselItem,
  previousCarouselItem,
  showImageModal
} from 'modules/product-images/action-creators';

import CarouselContainer from 'components/core/carousel/container';
import CarouselItems from 'components/core/carousel/items';
import CarouselItemThumbnails from 'components/core/carousel/item-thumbnails';
import CenteredResponsiveImage from 'components/core/centered-responsive-image';
import VimeoEmbed from 'components/core/vimeo-embed';

import GlobalState from 'GlobalState';

import {
  ProductImage,
  ProductVideo,
  ProductMedia as ProductMediaType
} from 'types';

interface Props {
  media: Array<ProductMediaType>;
  itemIndex: number;
  selectCarouselItem(index: number): void;
  previousCarouselItem(skipVideos: boolean): void;
  nextCarouselItem(skipVideos: boolean): void;
  showImageModal(): void;
}

class ProductMedia extends React.PureComponent<Props> {
  static defaultProps = (function () {
    const { product } = globalData();

    if (product != null) {
      return {
        media: product.media
      };
    }

    return {};
  })();

  renderMedia() {
    const { media, itemIndex, showImageModal } = this.props;

    return media.map((media, index) => {
      if (media.type === 'image') {
        const image: ProductImage = media.content;

        return (
          <div key={image.id} className="ProductMedia-item">
            <div className="ProductMedia-imageContainer">
              <img
                className="ProductMedia-image"
                srcSet={[
                  `${image.urls['small']} 100w 100h`,
                  `${image.urls['product']} 240w 240h`,
                  `${image.urls['large']} 600w 450h`,
                  `${image.urls['extra_large']} 1200w 900h`,
                  `${image.urls['extra_large_2x']} 2400w 1800h`
                ].join(', ')}
                sizes={[
                  `(min-width: 1400px) and (min-height: 850px) 100vw`,
                  `50vw`
                ].join(', ')}
                onClick={showImageModal}
              />
            </div>

            <div className="ProductMedia-item-message">Click To Zoom</div>
          </div>
        );
      }

      if (media.type === 'video') {
        const video: ProductVideo = media.content;
        const isCurrent = index === itemIndex;

        return (
          <div key={video.id} className="ProductMedia-item">
            <VimeoEmbed videoID={video.vimeo_id} isCurrent={isCurrent} />
          </div>
        );
      }

      return null;
    });
  }

  renderThumbnails() {
    const { media } = this.props;
    return media.map((media: ProductMediaType) => {
      if (media.type === 'image') {
        const image: ProductImage = media.content;

        const thumbnailUrls: [string, string][] = [
          [image.urls.small, '1x'],
          [image.urls.small_2x, '2x']
        ];

        return (
          <CenteredResponsiveImage key={image.id} srcset={thumbnailUrls} />
        );
      }

      if (media.type === 'video') {
        const video: ProductVideo = media.content;
        return (
          <img
            key={video.id}
            className="CarouselItemThumbnails-item-video"
            src={video.preview_url}
          />
        );
      }

      return null;
    });
  }

  render() {
    const {
      media,
      itemIndex,
      selectCarouselItem,
      previousCarouselItem,
      nextCarouselItem
    } = this.props;

    const itemCount = media.length;

    const containerProps = {
      itemCount,
      itemIndex,
      selectItem: selectCarouselItem,
      previousItem: previousCarouselItem,
      nextItem: nextCarouselItem
    };

    return (
      <div className="ProductMedia">
        <CarouselContainer {...containerProps}>
          <CarouselItems>{this.renderMedia()}</CarouselItems>

          <CarouselItemThumbnails>
            {this.renderThumbnails()}
          </CarouselItemThumbnails>
        </CarouselContainer>
      </div>
    );
  }
}

export default provideStoreToComponent(
  connect(
    (state: GlobalState) => {
      return {
        itemIndex: state.productImages.carouselItemIndex
      };
    },
    (dispatch) => {
      return {
        selectCarouselItem(index: number) {
          dispatch(selectCarouselItem(index));
        },
        previousCarouselItem(skipVideos: boolean) {
          dispatch(previousCarouselItem(skipVideos));
        },
        nextCarouselItem(skipVideos: boolean) {
          dispatch(nextCarouselItem(skipVideos));
        },
        showImageModal() {
          dispatch(showImageModal());
        }
      };
    }
  )(ProductMedia)
);
