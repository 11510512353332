import React from 'react';

import CarouselContainer from 'components/core/carousel/container';
import CarouselItems from 'components/core/carousel/items';
import { FontFeature } from 'types';

interface Props {
  features: FontFeature[];
}

const FontFamilyFeatures: React.FC<Props> = (props) => {
  const { features } = props;

  const items = features.map((feature, index) => {
    return (
      <div key={index} className="FontFeatures-feature-container">
        <img src={feature.assetURL} className="FontFeatures-feature" />
        <p className="FontFeatures-content">{feature.content}</p>
      </div>
    );
  });

  return (
    <div className="FontFeatures">
      <CarouselContainer itemCount={features.length}>
        <CarouselItems>{items}</CarouselItems>
      </CarouselContainer>
    </div>
  );
};

export default FontFamilyFeatures;
