import React, { useContext, useMemo, useCallback } from 'react';

import { setIsSuperset } from 'utils/sets';
import { connect } from 'react-redux';
import provideStoreToComponent from 'app-provider';

import {
  addLicenseStyles,
  removeLicenseStyles
} from 'modules/font-licensing/action-creators';
import Context from './context';
import { FontStyle, LicenseType } from 'types';
import GlobalState from 'GlobalState';

interface Props {
  styles: FontStyle[];
  selectedStyles: {
    desktop: Set<number>;
    web: Set<number>;
    app: Set<number>;
  };
  addLicenseStyles(licenseType: LicenseType, ids: number[]): void;
  removeLicenseStyles(licenseType: LicenseType, ids: number[]): void;
}

const FontLicensingStyleSelectAll: React.FC<Props> = (props) => {
  const { styles, selectedStyles, removeLicenseStyles, addLicenseStyles } =
    props;

  const { licenseType } = useContext(Context);

  const checked = useMemo(() => {
    if (licenseType == null) {
      return false;
    }

    const styleIds = new Set(styles.map((style) => style.id));

    return setIsSuperset(selectedStyles[licenseType], styleIds);
  }, [styles, selectedStyles]);

  const handleChange = useCallback(() => {
    if (licenseType == null) {
      return;
    }

    const styleIds = styles.map((style) => style.id);

    if (checked) {
      removeLicenseStyles(licenseType, styleIds);
    } else {
      addLicenseStyles(licenseType, styleIds);
    }
  }, [checked, styles, licenseType]);

  return (
    <label className="FontLicensingStyleSelectAll">
      <input type="checkbox" checked={checked} onChange={handleChange} />

      <span>Select all</span>
    </label>
  );
};

export default provideStoreToComponent(
  connect(
    (state: GlobalState) => {
      return {
        selectedStyles: state.fontLicensing.selectedStyles
      };
    },
    (dispatch) => {
      return {
        addLicenseStyles(licenseType: LicenseType, ids: number[]) {
          dispatch(addLicenseStyles(licenseType, ids));
        },
        removeLicenseStyles(licenseType: LicenseType, ids: number[]) {
          dispatch(removeLicenseStyles(licenseType, ids));
        }
      };
    }
  )(FontLicensingStyleSelectAll)
);
