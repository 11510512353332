import React, { forwardRef } from 'react';
import Features from './features';
import { FontFeature } from 'types';

interface Props {
  features: FontFeature[];
  isVisible: boolean;
}

const FontFamilyFeaturesSection = forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { features, isVisible } = props;

    if (isVisible) {
      return (
        <div ref={ref} className="features">
          <h2>Features</h2>
          <Features features={features} />
        </div>
      );
    }

    return null;
  }
);

export default FontFamilyFeaturesSection;
