import $ from 'jquery';

$('[data-recaptcha-action]').each((index, element: HTMLFormElement) => {
  element.addEventListener(
    'submit',
    (event) => {
      if (window.grecaptcha == null) {
        return;
      }

      event.preventDefault();

      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(window.recaptchaSiteKey, {
            action: $(element).data('recaptcha-action')
          })
          .then((token: string) => {
            const input = document.createElement('input');

            input.setAttribute('type', 'hidden');
            input.setAttribute('name', 'recaptcha_token');
            input.setAttribute('value', token);

            element.appendChild(input);
            element.submit();
          });
      });
    },
    false
  );
});
