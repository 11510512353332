import React, { forwardRef } from 'react';

interface Props {
  description: string;
  isVisible: boolean;
}

const FontFamilyAboutSection = forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { description, isVisible } = props;

    if (isVisible) {
      return (
        <div ref={ref} className="description">
          <div
            className="content-column"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      );
    }

    return null;
  }
);

export default FontFamilyAboutSection;
