import React from 'react';
import $ from 'jquery';

interface Props {
  method: 'get' | 'post' | 'patch' | 'delete';
}

const FormRailsHiddenInputs: React.FC<Props> = (props) => {
  const { method } = props;

  const token = $('meta[name="csrf-token"]').attr('content');

  return (
    <span>
      <input name="utf8" type="hidden" value="✓" />
      <input type="hidden" name="_method" value={method} />
      <input type="hidden" name="authenticity_token" value={token} />
    </span>
  );
};

export default FormRailsHiddenInputs;
