import React, { forwardRef } from 'react';
import Specimens from './specimens';
import { FontSpecimen } from 'types';

interface Props {
  specimens: FontSpecimen[];
  isVisible: boolean;
}

const FontFamilySpecimensSection = forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { specimens, isVisible } = props;

    if (isVisible) {
      return (
        <div ref={ref} className="specimens">
          <Specimens specimens={specimens} />
        </div>
      );
    }

    return null;
  }
);

export default FontFamilySpecimensSection;
