import React from 'react';

import ProductOptionValue from 'components/product/option-value';

import { OptionValue, OptionType } from 'types';

interface Props {
  optionType: OptionType;
  selectedOptionValues: OptionValue[];
  selectOptionValue(optionValue: OptionValue): void;
  optionValueIsDisabled(optionValue: OptionValue): boolean;
}

interface State {
  selectedOptionValue: OptionValue | null;
}

class ProductOption extends React.Component<Props, State> {
  state = {
    selectedOptionValue: null
  };

  selectOptionValue = (optionValue: OptionValue) => {
    this.props.selectOptionValue(optionValue);
  };

  render() {
    const { optionValueIsDisabled, selectedOptionValues } = this.props;

    const optionValues = this.props.optionType.values.map((optionValue) => {
      return (
        <ProductOptionValue
          key={optionValue.id}
          optionValue={optionValue}
          disabled={optionValueIsDisabled(optionValue)}
          selectedOptionValues={selectedOptionValues}
          selectOptionValue={this.selectOptionValue}
        />
      );
    });

    return (
      <div className="ProductOption">
        <div className="ProductOption-name">{this.props.optionType.name}</div>
        <div className="ProductOption-values">{optionValues}</div>
      </div>
    );
  }
}

export default ProductOption;
