import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import provideStoreToComponent from 'app-provider';

import { FontStyle, LicenseType } from 'types';
import GlobalState from 'GlobalState';

import {
  addLicenseStyles,
  removeLicenseStyles
} from 'modules/font-licensing/action-creators';

import Context from './context';
import { useDeclarationClassName } from 'modules/font-declarations';

interface Props {
  styles: FontStyle[];
  selectedStyles: {};
  addLicenseStyles(licenseType: LicenseType, ids: number[]): void;
  removeLicenseStyles(licenseType: LicenseType, ids: number[]): void;
}

class FontLicensingStyleList extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return nextProps.selectedStyles !== this.props.selectedStyles;
  }

  render() {
    const { styles, selectedStyles, addLicenseStyles, removeLicenseStyles } =
      this.props;

    return (
      <Context.Consumer>
        {({ licenseType }) => {
          if (licenseType == null) {
            return null;
          }

          const selectedStylesOfType = selectedStyles[licenseType];

          return (
            <ul className="FontLicensingStyleList">
              {styles.map((style) => {
                const checked = selectedStylesOfType.has(style.id);

                const handleChange = (
                  event: React.ChangeEvent<HTMLInputElement>
                ) => {
                  if (event.currentTarget.checked) {
                    addLicenseStyles(licenseType, [style.id]);
                  } else {
                    removeLicenseStyles(licenseType, [style.id]);
                  }
                };

                if (style.nameAsSvg == null) {
                  return (
                    <TextStyleItem
                      key={style.id}
                      fontStyle={style}
                      checked={checked}
                      onChange={handleChange}
                    />
                  );
                } else {
                  return (
                    <SVGStyleItem
                      key={style.id}
                      fontStyle={style}
                      checked={checked}
                      onChange={handleChange}
                    />
                  );
                }
              })}
            </ul>
          );
        }}
      </Context.Consumer>
    );
  }
}

const TextStyleItem: React.FC<{
  fontStyle: FontStyle;
  checked: boolean;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
}> = (props) => {
  const { fontStyle, checked, onChange } = props;
  const className = useDeclarationClassName(fontStyle.id);

  return (
    <li
      key={fontStyle.id}
      className={classNames(
        'FontLicensingStyleList-item',
        {
          'FontLicensingStyleList-item--selected': checked
        },
        className
      )}
    >
      <label>
        <input type="checkbox" checked={checked} onChange={onChange} />

        <span>{fontStyle.name}</span>
      </label>
    </li>
  );
};

const SVGStyleItem: React.FC<{
  fontStyle: FontStyle;
  checked: boolean;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
}> = (props) => {
  const { fontStyle, checked, onChange } = props;

  return (
    <li
      key={fontStyle.id}
      className={classNames('FontLicensingStyleList-item', {
        'FontLicensingStyleList-item--selected': checked
      })}
    >
      <label>
        <input type="checkbox" checked={checked} onChange={onChange} />

        <span
          className="name-as-svg"
          dangerouslySetInnerHTML={{ __html: fontStyle.nameAsSvg }}
        />
      </label>
    </li>
  );
};

export default provideStoreToComponent(
  connect(
    (state: GlobalState) => {
      return {
        selectedStyles: state.fontLicensing.selectedStyles
      };
    },
    (dispatch) => {
      return {
        addLicenseStyles(licenseType: LicenseType, ids: number[]) {
          dispatch(addLicenseStyles(licenseType, ids));
        },
        removeLicenseStyles(licenseType: LicenseType, ids: number[]) {
          dispatch(removeLicenseStyles(licenseType, ids));
        }
      };
    }
  )(FontLicensingStyleList)
);
