import './klaviyo-form.scss';

import React, { useLayoutEffect } from 'react';
import $ from 'jquery';

import useScript from 'utils/useScript';

interface Props {
  listId: string;
}

const TITLE_OPTIONS = [
  'Art Director',
  'Brand Enthusiast',
  'Event Attendee',
  'Freelancer',
  'Graphic Designer',
  'IT Professional',
  'Member of the Press'
];

const KlaviyoForm: React.FC<Props> = (props) => {
  const { listId } = props;

  const klaviyoLoaded = useScript(
    '//www.klaviyo.com/media/js/public/klaviyo_subscribe.js'
  );

  useLayoutEffect(() => {
    if (klaviyoLoaded && window.KlaviyoSubscribe != null) {
      window.KlaviyoSubscribe.attachToForms('#klaviyo-form', {
        hide_form_on_success: true
      });
    }
  }, [klaviyoLoaded]);

  if (!klaviyoLoaded) {
    return null;
  }

  return (
    <form
      id="klaviyo-form"
      className="KlaviyoForm"
      action="//manage.kmail-lists.com/subscriptions/subscribe"
      data-ajax-submit="//manage.kmail-lists.com/ajax/subscriptions/subscribe"
      method="GET"
      target="_blank"
      onSubmit={() => {
        if (window._learnq != null) {
          window._learnq.push([
            'identify',
            {
              $email: $("input[name='email']").val(),
              $title: $("select[name='Title'] :selected").val()
            }
          ]);
        }
      }}
    >
      <input type="hidden" name="g" value={listId} />

      <input
        type="hidden"
        name="$fields"
        value="$first_name,$last_name,$zip,Title"
      />

      <div className="klaviyo_form_actions">
        <h2>What’s up at House?</h2>

        <div className="KlaviyoForm-row">
          <input
            type="text"
            name="$first_name"
            placeholder="First Name"
            autoComplete="given-name"
          />

          <input
            type="text"
            name="$last_name"
            placeholder="Last Name"
            autoComplete="family-name"
          />
        </div>

        <div className="KlaviyoForm-row">
          <input
            type="email"
            name="email"
            placeholder="Enter Your Email!"
            autoComplete="email"
          />

          <input
            type="text"
            name="$zip"
            placeholder="ZIP Code"
            autoComplete="postal-code"
            required
          />
        </div>

        <div className="KlaviyoForm-row">
          <div className="KlaviyoForm-select">
            <select name="Title" required>
              <option value="">I’m a…</option>
              {TITLE_OPTIONS.map((title) => (
                <option key={title} value={title}>
                  {title}
                </option>
              ))}
            </select>
          </div>

          <button className="klaviyo_submit_button" type="submit">
            Submit
          </button>
        </div>

        <p>Stay up to date on new releases, products and events</p>
      </div>

      <p className="success_message" style={{ display: 'none' }} />
      <p className="error_message" style={{ display: 'none' }} />
    </form>
  );
};

export default KlaviyoForm;
