import React from 'react';

import { some } from 'lodash';

import { connect } from 'react-redux';
import provideStoreToComponent from 'app-provider';

import { addLicenseToOrderStart } from 'modules/font-licensing/action-creators';

import GlobalState from 'GlobalState';

interface Props {
  isActing: boolean;
  selectedStyles: {
    app: Set<number>;
    desktop: Set<number>;
    web: Set<number>;
  };
  addLicenseToOrder(): void;
}

const AddToBagButton: React.FC<Props> = (props) => {
  const { isActing, selectedStyles, addLicenseToOrder } = props;

  const text = isActing ? 'Adding...' : 'Add To Bag';

  const anyStylesAreSelected = some(selectedStyles, (styleSet) => {
    return styleSet.size > 0;
  });

  const isDisabled = !anyStylesAreSelected || isActing;

  return (
    <button
      className="FontLicensingSection-add-to-bag"
      onClick={addLicenseToOrder}
      disabled={isDisabled}
    >
      {text}
    </button>
  );
};

export default provideStoreToComponent(
  connect(
    (state: GlobalState) => {
      return {
        isActing: state.fontLicensing.isActing,
        selectedStyles: state.fontLicensing.selectedStyles
      };
    },
    (dispatch) => {
      return {
        addLicenseToOrder() {
          dispatch(addLicenseToOrderStart());
        }
      };
    }
  )(AddToBagButton)
);
