import React from 'react';
import $ from 'jquery';

class ProductRestockNotificationForm extends React.PureComponent {
  props: { variantId: number };

  state = {
    email: '',
    notificationCreated: false
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      email: event.target.value
    });
  };

  createNotification = () => {
    const { variantId } = this.props;

    if (variantId && this.state.email && this.state.email.length) {
      const options = {
        url: '/notifications/restock',
        type: 'POST',
        data: {
          notification: {
            email: this.state.email,
            variant_id: variantId
          }
        }
      };

      $.ajax(options).then(() => {
        this.setState({
          notificationCreated: true
        });
      });
    }
  };

  render() {
    if (this.state.notificationCreated) {
      return (
        <div className="ProductRestockNotificationForm">
          <p>Thanks! We'll let you know when it's back in stock.</p>
        </div>
      );
    } else {
      return (
        <div className="ProductRestockNotificationForm">
          <p>Email me when this item is back in stock.</p>

          <div>
            <input
              type="email"
              placeholder="Email"
              value={this.state.email}
              onChange={this.handleInputChange}
            />

            <button onClick={this.createNotification}>Notify Me</button>
          </div>
        </div>
      );
    }
  }
}

export default ProductRestockNotificationForm;
