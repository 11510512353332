import React from 'react';
import classNames from 'classnames';

const SocialMediaLinks: React.FC = () => {
  const classes = classNames('SocialMediaLinks', {
    'SocialMediaLinks--hidden': /^\/(products)/.test(window.location.pathname)
  });

  return (
    <div className={classes}>
      <a
        className="SocialMediaLinks-twitter"
        href="https://twitter.com/houseindustries"
      />
      <a
        className="SocialMediaLinks-instagram"
        href="https://instagram.com/houseindustries"
      />
      <a
        className="SocialMediaLinks-facebook"
        href="https://www.facebook.com/pages/House-Industries/1542356156000464"
      />
    </div>
  );
};

export default SocialMediaLinks;
