interface ToggleSearchVisibilityAction {
  type: 'TOGGLE_SEARCH_VISIBILITY';
  isVisible?: boolean;
}

export function toggleSearchVisibility(
  isVisible?: boolean
): ToggleSearchVisibilityAction {
  return { type: 'TOGGLE_SEARCH_VISIBILITY', isVisible };
}

interface ToggleNavigationVisibilityAction {
  type: 'TOGGLE_NAVIGATION_VISIBILITY';
  isVisible?: boolean;
}

export function toggleNavigationVisibility(
  isVisible?: boolean
): ToggleNavigationVisibilityAction {
  return { type: 'TOGGLE_NAVIGATION_VISIBILITY', isVisible };
}

// This is unused, because the only place this action is
// dispatched is from _navigation.html.slim, which
// constructs the action directly to make things easier.
// If/when navigation is turned into a React component,
// the following function will be used to show the modal.

interface ShowModalAction {
  type: 'SHOW_MODAL';
  modalName: 'MAILING_LIST';
}

export function showModal(modalName: 'MAILING_LIST'): ShowModalAction {
  return { type: 'SHOW_MODAL', modalName };
}

interface HideModalAction {
  type: 'HIDE_MODAL';
}

export function hideModal(): HideModalAction {
  return { type: 'HIDE_MODAL' };
}

export type Action =
  | ToggleSearchVisibilityAction
  | ToggleNavigationVisibilityAction
  | ShowModalAction
  | HideModalAction;
