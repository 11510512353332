import React from 'react';
import VimeoEmbed from 'components/core/vimeo-embed';
import classNames from 'classnames';
import { HomePageItem } from 'types';

interface ImageItem extends HomePageItem {
  image_urls: {
    [name: string]: string;
  };
}

interface VideoItem extends HomePageItem {
  vimeo_id: string;
}

function isImageItem(value: any): value is ImageItem {
  return (
    value != null &&
    typeof value === 'object' &&
    'image_urls' in value &&
    value.image_urls != null
  );
}

function isVideoItem(value: any): value is VideoItem {
  return (
    value != null &&
    typeof value === 'object' &&
    'vimeo_id' in value &&
    typeof value.vimeo_id === 'string' &&
    value.vimeo_id.length > 0
  );
}

interface Props {
  isCurrent: boolean;
  item: HomePageItem;
  sectionType: string;
  className: string;
  onVideoPlay?(): void;
  onVideoPause?(): void;
}

/**
 * Renders a single element for the home page, including an image, link, and small block of
 * text.
 */
class HomePageFeaturesItem extends React.PureComponent<Props> {
  /**
   * Render the media for an item, either an image or a vimeo embed iframe.
   */
  renderMedia(item: HomePageItem) {
    // FIXME: This is horrible. The "sectionType" is a string that has to be
    // parsed to obtain the home page item size.
    const sectionSize = this.props.sectionType.split(' ')[0];

    if (isVideoItem(item)) {
      return (
        <VimeoEmbed
          isCurrent={this.props.isCurrent}
          videoID={item.vimeo_id}
          onPlay={this.props.onVideoPlay}
          onPause={this.props.onVideoPause}
        />
      );
    } else if (isImageItem(item)) {
      let sizes: string[] = [];

      if (sectionSize === 'small') {
        sizes = [`(max-width: 765px) 45vw`, `(max-width: 1798px) 24vw`, `16vw`];
      } else {
        sizes = [`(max-width: 1798px) 100vw`, `66vw`];
      }

      if (item.has_product) {
        return (
          <img
            src={item.image_urls['extra_large']}
            srcSet={[
              `${item.image_urls['small']} 100w`,
              `${item.image_urls['product']} 240w`,
              `${item.image_urls['large']} 600w`,
              `${item.image_urls['extra_large']} 1200w`,
              `${item.image_urls['extra_large_2x']} 2400w`
            ].join(', ')}
            sizes={sizes.join(', ')}
          />
        );
      } else {
        return (
          <img
            src={item.image_urls['1600w']}
            srcSet={[
              `${item.image_urls['60w']} 60w`,
              `${item.image_urls['200w']} 200w`,
              `${item.image_urls['400w']} 400w`,
              `${item.image_urls['800w']} 800w`,
              `${item.image_urls['1600w']} 1600w`
            ].join(', ')}
            sizes={sizes.join(', ')}
          />
        );
      }
    }

    return null;
  }

  renderLarge(label: React.ReactNode, classes: string) {
    const hasMediaLink = this.props.item.img_link !== null;
    const hasLabelLink = this.props.item.link_url !== null;

    return (
      <div className={classes}>
        <div className="HomePageFeaturesItem-content">
          <OptionalLink
            className="HomePageFeaturesItem-media"
            href={this.props.item.img_link}
            isEnabled={hasMediaLink}
          >
            {this.renderMedia(this.props.item)}
          </OptionalLink>

          <OptionalLink
            className="HomePageFeaturesItem-label"
            href={this.props.item.link_url}
            isEnabled={hasLabelLink}
          >
            {label}
          </OptionalLink>
        </div>
      </div>
    );
  }

  renderSmall(label: React.ReactNode, classes: string) {
    return (
      <div className={classes}>
        <OptionalLink
          className="HomePageFeaturesItem-content"
          href={this.props.item.link_url}
          isEnabled={isImageItem(this.props.item)}
        >
          <div className="HomePageFeaturesItem-media">
            {this.renderMedia(this.props.item)}
          </div>

          <OptionalLink
            className="HomePageFeaturesItem-label"
            href={this.props.item.link_url}
            isEnabled={isVideoItem(this.props.item)}
          >
            {label}
          </OptionalLink>
        </OptionalLink>
      </div>
    );
  }

  render() {
    const label = this.props.item.label.split('\n').map((line, index) => {
      return <div key={index}>{line}</div>;
    });

    const classes = classNames('HomePageFeaturesItem', this.props.className);

    const sectionType = this.props.sectionType;

    if (sectionType.indexOf('large') !== -1) {
      return this.renderLarge(label, classes);
    } else {
      return this.renderSmall(label, classes);
    }
  }
}

/**
 * An ad-hoc component to wrap content in a link, or an empty span if the given 'href' is
 * blank. 'isEnabled' can be set to also force no link which can be used to conditionally
 * control the link.
 */
const OptionalLink: React.FC<{
  href?: string | null;
  className: string;
  isEnabled: boolean;
}> = (props) => {
  const { href, className, isEnabled, children } = props;

  if (href != null && href != '' && isEnabled !== false) {
    return (
      <a href={href} className={className}>
        {children}
      </a>
    );
  } else {
    return <div className={className}>{children}</div>;
  }
};

export default HomePageFeaturesItem;
