import { remove } from 'lodash';

const StripeLoader = {
  handlers: [] as (() => void)[],
  isLoaded: false,

  didLoad() {
    if (!this.isLoaded) {
      this.isLoaded = true;

      while (this.handlers.length > 0) {
        const callback = this.handlers.pop();

        if (callback != null) {
          setTimeout(callback, 0);
        }
      }
    }
  },

  onLoad(callback: () => void) {
    const handlers = this.handlers;

    if (this.isLoaded) {
      callback();
    } else {
      handlers.push(callback);
    }

    return function () {
      remove(handlers, callback);
    };
  }
};

if ('Stripe' in window) {
  StripeLoader.didLoad();
}

export default StripeLoader;
