import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { loadFontDeclaration } from './action-creators';
import GlobalState from 'GlobalState';

export function useDeclarations() {
  return useSelector((state: GlobalState) => {
    return Object.entries(state.fontDeclarations).map(
      ([id, { declaration }]) => ({
        id,
        declaration
      })
    );
  });
}

export function useDeclarationClassName(
  fontStyleId: number
): string | undefined {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadFontDeclaration({ id: fontStyleId }));
  }, [fontStyleId]);

  return useSelector((state: GlobalState) => {
    const declaration = state.fontDeclarations[fontStyleId];

    return declaration != null ? declaration.className : undefined;
  });
}
