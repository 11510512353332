import React from 'react';
import { find, filter, every, includes, compact } from 'lodash';

import { updateSelectedVariant } from 'modules/product-images/action-creators';

import { connect } from 'react-redux';
import provideStoreToComponent from 'app-provider';

import ProductOption from 'components/product/option';

import { Variant, OptionType, OptionValue } from 'types';

interface Props {
  variants: Variant[];
  optionTypes: OptionType[];
  selectedVariant?: Variant;
  updateSelectedVariant(variantId: number | null): void;
}

interface State {
  selectedOptionValues: OptionValue[];
}

class ProductOptions extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { selectedVariant } = props;

    let selectedOptionValues: OptionValue[] = [];

    if (selectedVariant) {
      selectedOptionValues = this.optionValuesForVariant(selectedVariant);
    }

    this.state = {
      selectedOptionValues
    };
  }

  optionValuesForVariant(variant: Variant): OptionValue[] {
    const { optionTypes } = this.props;

    return compact(
      variant.options.map((option: OptionValue) => {
        const optionType = optionTypes.find((optionType: OptionType) => {
          return optionType.id === option.option_type_id;
        });

        if (optionType != null) {
          return optionType.values.find((optionValue: OptionValue) => {
            return optionValue.id === option.id;
          });
        }

        return null;
      })
    );
  }

  variantsForOptionValues(optionValues: OptionValue[]): Variant[] {
    return filter(this.props.variants, (variant: Variant) => {
      return every(optionValues, (option: OptionValue) => {
        return variant.options.some((vo) => {
          return (
            vo.id === option.id && vo.option_type_id === option.option_type_id
          );
        });
      });
    });
  }

  optionValueIsDisabled = (optionValue: OptionValue): boolean => {
    const optionValues: OptionValue[] = [];
    const { optionTypes } = this.props;
    const { selectedOptionValues } = this.state;

    if (optionTypes == null) {
      return true;
    }

    for (const optionType of optionTypes) {
      let ov;

      const optionValueIds = optionType.values.map((value) => {
        return value.id;
      });

      if (includes(optionValueIds, optionValue.id)) {
        optionValues.push(optionValue);
        return false;
      } else {
        ov = find(selectedOptionValues, (ov) => {
          return ov.option_type_id === optionType.id;
        });

        if (ov) {
          optionValues.push(ov);
        }
      }
    }

    return this.variantsForOptionValues(optionValues).every(function (variant) {
      return !variant.in_stock;
    });
  };

  selectOptionValue = (optionValue: OptionValue) => {
    if (!includes(this.state.selectedOptionValues, optionValue)) {
      const selectedOptionValues = filter(
        this.state.selectedOptionValues,
        function (value) {
          return value.option_type_id !== optionValue.option_type_id;
        }
      );

      selectedOptionValues.push(optionValue);

      this.setState({
        selectedOptionValues
      });

      if (selectedOptionValues.length === this.props.optionTypes.length) {
        const variant = this.variantsForOptionValues(selectedOptionValues)[0];

        this.props.updateSelectedVariant(variant ? variant.id : null);
      }
    }
  };

  render() {
    const options = this.props.optionTypes.map((optionType) => {
      return (
        <ProductOption
          key={optionType.id}
          optionType={optionType}
          selectedOptionValues={this.state.selectedOptionValues}
          selectOptionValue={this.selectOptionValue}
          optionValueIsDisabled={this.optionValueIsDisabled}
        />
      );
    });

    return <div className="ProductOptions">{options}</div>;
  }
}

export default provideStoreToComponent(
  connect(null, (dispatch) => {
    return {
      updateSelectedVariant(variantId: number | null) {
        dispatch(updateSelectedVariant(variantId));
      }
    };
  })(ProductOptions)
);
