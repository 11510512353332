import React, { createRef } from 'react';
import $ from 'jquery';
import Player from '@vimeo/player';
import classNames from 'classnames';
import { debounce } from 'lodash';

interface Props {
  videoID: string;
  isCurrent?: boolean;
  className?: string;
  onPlay?(): void;
  onPause?(): void;
}

interface State {
  videoSize?: {
    width: number;
    height: number;
  };
  containerSize: {
    width: number;
    height: number;
  };
  isLoaded: boolean;
}

/**
 * Render an embedded vimeo video from it's id. Emits actions when video playback starts
 * or stops.
 */
class VimeoEmbed extends React.PureComponent<Props, State> {
  static defaultProps: {
    isCurrent: true;
  };

  state: State = {
    containerSize: {
      width: 0,
      height: 0
    },
    isLoaded: false
  };

  node = createRef<HTMLDivElement>();
  iframeNode = createRef<HTMLIFrameElement>();
  player?: Player;

  handleWindowResize = debounce(() => {
    this.updateContainerSize();
  }, 10);

  updateContainerSize() {
    const { videoSize } = this.state;
    const { current: node } = this.node;

    if (node != null && videoSize != null) {
      const scale = Math.min(
        ($(node).innerHeight() || 0) / videoSize.height,
        ($(node).innerWidth() || 0) / videoSize.width
      );

      this.setState({
        containerSize: {
          width: Math.round(videoSize.width * scale),
          height: Math.round(videoSize.height * scale)
        }
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.isCurrent && this.props.isCurrent) {
      this.updateContainerSize();
    }

    if (prevProps.isCurrent && !this.props.isCurrent) {
      this.pause();
    }
  }

  componentDidMount() {
    const { onPlay, onPause } = this.props;

    if (this.iframeNode.current != null) {
      this.player = new Player(this.iframeNode.current);
    }

    if (this.player == null) {
      // TODO: Throw an error once error boundaries are implemented?
      return;
    }

    // Fetch dimensions of the video so we can render the correct aspect ratio.
    Promise.all([
      this.player.getVideoWidth(),
      this.player.getVideoHeight()
    ]).then(([width, height]) => {
      this.setState({
        videoSize: { width, height },
        isLoaded: true
      });

      this.updateContainerSize();
    });

    this.player.on('play', () => {
      if (onPlay != null) {
        onPlay();
      }
    });

    this.player.on('pause', () => {
      if (onPause != null) {
        onPause();
      }
    });

    this.player.on('ended', () => {
      if (onPause != null) {
        onPause();
      }
    });

    this.updateContainerSize();

    $(window).on('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    $(window).off('resize', this.handleWindowResize);

    if (this.player == null) {
      return;
    }

    this.player.off('play');
    this.player.off('pause');
    this.player.off('ended');
    this.player = undefined;
  }

  pause() {
    if (this.player != null) {
      this.player.pause();
    }
  }

  render() {
    const classes = classNames('VimeoEmbed', {
      'VimeoEmbed--loaded': this.state.isLoaded
    });
    const containerStyles = {
      width: `${this.state.containerSize.width}px`,
      height: `${this.state.containerSize.height}px`
    };

    return (
      <div className={classes} ref={this.node}>
        <div className="VimeoEmbed-container" style={containerStyles}>
          <iframe
            ref={this.iframeNode}
            src={`https://player.vimeo.com/video/${this.props.videoID}`}
            width="100%"
            height="100%"
            frameBorder="0"
            allowFullScreen
          />
        </div>
      </div>
    );
  }
}

export default VimeoEmbed;
