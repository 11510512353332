import React from 'react';
import { includes } from 'lodash';
import classNames from 'classnames';

import { OptionValue } from 'types';

interface Props {
  optionValue: OptionValue;
  selectedOptionValues: Array<OptionValue>;
  disabled?: boolean;
  selectOptionValue: (optionValue: OptionValue) => void;
}

class ProductOptionValue extends React.PureComponent<Props> {
  handleClick = () => {
    this.props.selectOptionValue(this.props.optionValue);
  };

  render() {
    const classes = classNames('ProductOptionValue', {
      'ProductOptionValue--selected': includes(
        this.props.selectedOptionValues,
        this.props.optionValue
      ),
      'ProductOptionValue--disabled': this.props.disabled
    });

    return (
      <button className={classes} onClick={this.handleClick}>
        {this.props.optionValue.name}
      </button>
    );
  }
}

export default ProductOptionValue;
