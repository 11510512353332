import React from 'react';

import { connect } from 'react-redux';
import provideStoreToComponent from 'app-provider';

import CoreSelect from 'components/core/select';

import {
  updateLicenseOption,
  showLicensingAgreementModal,
  showLicensingDescriptionModal
} from 'modules/font-licensing/action-creators';

import Context from './context';
import { LicenseType } from 'types';
import GlobalState from 'GlobalState';

interface Props {
  selectedOption: {};
  options: { id: number | 'EXTRA'; label: string }[];
  labelSuffix: string;
  labelSuffixSingular?: string;
  updateLicenseOption(licenseType: LicenseType, id: number | 'EXTRA'): void;
  showLicensingAgreementModal(licenseType: LicenseType): void;
  showLicensingDescriptionModal(licenseType: LicenseType): void;
}

class FontLicensingOptions extends React.Component<Props> {
  labelSuffix(option: { label: string }) {
    const { labelSuffix, labelSuffixSingular } = this.props;

    if (labelSuffixSingular != null && option.label === '1') {
      return labelSuffixSingular;
    }

    return labelSuffix;
  }

  renderOptions() {
    const { options } = this.props;

    return options.map((option) => (
      <option key={option.id} value={option.id}>
        {option.label} {this.labelSuffix(option)}
      </option>
    ));
  }

  render() {
    const {
      selectedOption,
      updateLicenseOption,
      showLicensingAgreementModal,
      showLicensingDescriptionModal
    } = this.props;

    return (
      <Context.Consumer>
        {({ licenseType }) => {
          if (licenseType == null) {
            return null;
          }

          return (
            <div className="FontLicensingOptions">
              <CoreSelect
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  const { value } = event.currentTarget;

                  if (value === 'EXTRA') {
                    updateLicenseOption(licenseType, 'EXTRA');
                  } else {
                    updateLicenseOption(licenseType, parseInt(value));
                  }
                }}
                value={selectedOption[licenseType]}
              >
                {this.renderOptions()}
              </CoreSelect>

              {
                <div className="FontLicensingModalButtons">
                  <button
                    className="FontLicensingModalButtons-license"
                    onClick={(event) => {
                      showLicensingAgreementModal(licenseType);
                      event.stopPropagation()
                    }}
                  >
                    View License
                  </button>

                  <button
                    className="FontLicensingModalButtons-description"
                    onClick={(event) => {
                      showLicensingDescriptionModal(licenseType);
                      event.stopPropagation()
                    }}
                  >
                    ?
                  </button>
                </div>
              }
            </div>
          );
        }}
      </Context.Consumer>
    );
  }
}

export default provideStoreToComponent(
  connect(
    (state: GlobalState) => {
      return {
        selectedOption: state.fontLicensing.selectedOption
      };
    },
    (dispatch) => {
      return {
        updateLicenseOption(licenseType: LicenseType, id: number | 'EXTRA') {
          dispatch(updateLicenseOption(licenseType, id));
        },
        showLicensingAgreementModal(licenseType: LicenseType) {
          dispatch(showLicensingAgreementModal(licenseType));
        },
        showLicensingDescriptionModal(licenseType: LicenseType) {
          dispatch(showLicensingDescriptionModal(licenseType));
        }
      };
    }
  )(FontLicensingOptions)
);
