import React, { createRef } from 'react';
import $ from 'jquery';
import classNames from 'classnames';
import { debounce, find } from 'lodash';

type SrcSet = Array<[string, string]>;

type HTMLImageElementProps = Omit<
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >,
  'srcSet'
>;

export interface Props extends HTMLImageElementProps {
  srcset: SrcSet;
  loadImage: boolean;
  onLoadStart?(): void;
  onLoadEnd?(): void;
}

interface State {
  imageURL?: string;
  isLoading: boolean;
  hasLoaded: boolean;
}

class ResponsiveImage extends React.PureComponent<Props, State> {
  static defaultProps = {
    loadImage: true,
    onLoadStart: () => {},
    onLoadEnd: () => {}
  };

  state: State = {
    isLoading: false,
    hasLoaded: false
  };

  node = createRef<HTMLImageElement>();
  image?: HTMLImageElement;

  componentDidMount() {
    this.updateSource(this.props.srcset, this.props.loadImage);

    $(window).on('resize', this.handleWindowResize);
  }

  componentWillUpdate(nextProps: Props) {
    if (
      nextProps.srcset !== this.props.srcset ||
      nextProps.loadImage !== this.props.loadImage
    ) {
      this.updateSource(nextProps.srcset, nextProps.loadImage);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { onLoadStart, onLoadEnd } = this.props;

    if (
      onLoadStart != null &&
      prevState.isLoading === false &&
      this.state.isLoading === true
    ) {
      onLoadStart();
    }

    if (
      onLoadEnd != null &&
      prevState.isLoading === true &&
      this.state.isLoading === false
    ) {
      onLoadEnd();
    }
  }

  componentWillUnmount() {
    $(window).off('resize', this.handleWindowResize);
  }

  handleWindowResize = debounce(() => {
    this.updateSource(this.props.srcset, this.props.loadImage);
  }, 10);

  updateSource(srcset: SrcSet, loadImage: boolean) {
    if (loadImage === true && this.node != null) {
      const matchingSet = find(srcset, (set) => {
        const options = set[1];
        return matchesOptions(this.node.current, options);
      });

      if (matchingSet != null) {
        this.updateImageURL(matchingSet[0]);
      } else {
        const { current: node } = this.node;

        if (node != null && ($(node).parent().innerWidth() || 0) > 0) {
          this.updateImageURL(srcset[srcset.length - 1][0]);
        }
      }
    }
  }

  updateImageURL(imageURL: string) {
    if (this.state.imageURL !== imageURL) {
      this.image = new Image();

      this.image.addEventListener(
        'load',
        () => {
          this.setState({
            isLoading: false,
            hasLoaded: true
          });

          delete this.image;
        },
        false
      );

      this.setState({
        imageURL,
        isLoading: true,
        hasLoaded: false
      });

      this.image.src = imageURL;
    }
  }

  render() {
    const classes = classNames('ResponsiveImage', {
      'ResponsiveImage--loading': this.state.isLoading,
      'ResponsiveImage--loaded': this.state.hasLoaded
    });

    return (
      <img
        ref={this.node}
        className={classes}
        src={this.state.imageURL}
        onClick={this.props.onClick}
      />
    );
  }
}

const parentWidth = (element: HTMLElement) => {
  return $(element).parent().innerWidth() || 0;
};

const parentHeight = (element: HTMLElement) => {
  return $(element).parent().innerHeight() || 0;
};

const matchesWidth = (element: HTMLElement, width: number) => {
  return width != null && parentWidth(element) <= width;
};

const matchesHeight = (element: HTMLElement, height: number) => {
  return height != null && parentHeight(element) <= height;
};

const matchesPixelRatio = (ratio: number) => {
  return ratio != null && (window.devicePixelRatio || 1) <= ratio;
};

const matchesOptions = (element: HTMLElement | null, options: string) => {
  if (element == null) {
    return false;
  }

  let value;

  return options.split(/\s+/).every((option) => {
    if ((value = /^(\d+)w$/.exec(option))) {
      return matchesWidth(element, parseInt(value[1], 10));
    } else if ((value = /^(\d+)h$/.exec(option))) {
      return matchesHeight(element, parseInt(value[1], 10));
    } else if ((value = /^(\d+)x$/.exec(option))) {
      return matchesPixelRatio(parseInt(value[1], 10));
    } else {
      return false;
    }
  });
};

export default ResponsiveImage;
