import React, { createRef } from 'react';

type HTMLInputElementProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

interface Props extends HTMLInputElementProps {}

class SearchFormInput extends React.Component<Props> {
  inputNode = createRef<HTMLInputElement>();

  select() {
    if (this.inputNode.current) {
      this.inputNode.current.select();
    }
  }

  render() {
    const { value, ...props } = this.props;

    return (
      <div className="SearchForm-input">
        <input
          {...props}
          ref={this.inputNode}
          type="search"
          name="keywords"
          autoCapitalize="off"
          placeholder="Search House"
          value={value}
        />
      </div>
    );
  }
}

export default SearchFormInput;
