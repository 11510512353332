import React from 'react';

import CartLineItem from './line-item';
import { LineItem } from 'types';

interface Props {
  heading: string;
  lineItems: LineItem[];
}

const CartLineItemsSection = (props: Props) => {
  const { heading, lineItems } = props;

  return (
    <div className="CartLineItemsSection">
      <div className="CartLineItemsSection-heading">
        <div className="CartLineItemsSection-heading-inside">
          <div className="CartLineItemsSection-heading-text">{heading}</div>
        </div>
      </div>

      {lineItems.map((lineItem) => {
        return <CartLineItem key={lineItem.id} lineItem={lineItem} />;
      })}
    </div>
  );
};

export default CartLineItemsSection;
