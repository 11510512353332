import React from 'react';
import $ from 'jquery';

const CartActions: React.FC = () => {
  const csrfToken = $('meta[name="csrf-token"]').attr('content');

  return (
    <div className="CartActions">
      <div className="CartActions-inside">
        <div className="CartActions-secondary">
          <a className="primary-button" href="/">
            Continue Shopping
          </a>
        </div>

        <div className="CartActions-primary">
          <form action="/cart" method="post">
            <input name="_method" type="hidden" value="patch" />
            <input name="authenticity_token" type="hidden" value={csrfToken} />
            <input name="checkout" type="submit" value="Proceed to Checkout" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default CartActions;
