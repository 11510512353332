import React from 'react';
import classNames from 'classnames';

import Context from './context';

const CarouselItemThumbnails: React.FC = (props) => {
  const { children } = props;

  return (
    <Context.Consumer>
      {({ itemCount, itemIndex, selectItem }) => {
        if (itemCount > 1) {
          return (
            <div className="CarouselItemThumbnails">
              <span className="CarouselItemThumbnails-borders">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </span>

              {React.Children.map(children, (child, index) => {
                return (
                  <div
                    key={index}
                    className={classNames('CarouselItemThumbnails-item', {
                      'CarouselItemThumbnails-item--selected':
                        index === itemIndex
                    })}
                    onClick={() => {
                      selectItem(index);
                    }}
                  >
                    {child}
                  </div>
                );
              })}
            </div>
          );
        } else {
          return null;
        }
      }}
    </Context.Consumer>
  );
};

export default CarouselItemThumbnails;
