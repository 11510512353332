import GlobalState from 'GlobalState';
import { LineItem } from 'types';

export const totalOrderQuantity = (state: GlobalState) => {
  if (state.currentOrder == null || state.currentOrder.line_items == null) {
    return 0;
  }

  return state.currentOrder.line_items.reduce(
    (total: number, lineItem: LineItem) => {
      return total + lineItem.quantity;
    },
    0
  );
};
