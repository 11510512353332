import React, { createRef } from 'react';
import classNames from 'classnames';
import $ from 'jquery';
import { connect } from 'react-redux';
import provideStoreToComponent from 'app-provider';
import globalData from 'utils/global-data';

import { ProductMedia, ProductImage } from 'types';

import {
  nextCarouselItem,
  previousCarouselItem,
  hideImageModal
} from 'modules/product-images/action-creators';

import ResponsiveImage from 'components/core/responsive-image';
import GlobalState from 'GlobalState';

class ProductImagesModal extends React.Component {
  props: {
    imageIsLoading: boolean;
    itemIndex: number;
    imageModalIsVisible: boolean;
    hideImageModal(): void;
    previousCarouselItem(): void;
    nextCarouselItem(): void;
  };

  state = {
    imageIsLoading: false
  };

  node = createRef<HTMLDivElement>();

  productMedia(): ProductMedia[] {
    const { product } = globalData();

    if (product == null) {
      return [];
    }

    return product.media;
  }

  componentDidUpdate() {
    $('body').toggleClass('modal-visible', this.props.imageModalIsVisible);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown, false);

    $('body').removeClass('modal-visible');
  }

  onKeyDown = (event: KeyboardEvent) => {
    const escapeCode = 27;

    if (this.props.imageModalIsVisible && event.which === escapeCode) {
      hideImageModal();
    }

    return null;
  };

  handleModalClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.currentTarget === this.node.current) {
      hideImageModal();
    }
  };

  handleLoadStart = () => {
    this.setState({ imageIsLoading: true });
  };

  handleLoadEnd = () => {
    this.setState({ imageIsLoading: false });
  };

  renderArrows() {
    if (this.productMedia().length > 1) {
      return (
        <div>
          <button
            className="ProductImagesModal-arrow-left"
            onClick={this.props.previousCarouselItem}
          />
          <button
            className="ProductImagesModal-arrow-right"
            onClick={this.props.nextCarouselItem}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  renderImage(image: ProductImage) {
    const productImageUrls = [
      [image.urls.large_mobile, '650w 1x'],
      [image.urls.large_mobile_2x, '650w 3x'],
      [image.urls.large, '1000w 1x'],
      [image.urls.large_2x, '1000w 3x'],
      [image.urls.extra_large, '1x'],
      [image.urls.extra_large_2x, '3x']
    ] as [string, string][];

    return (
      <ResponsiveImage
        srcset={productImageUrls}
        onLoadStart={this.handleLoadStart}
        onLoadEnd={this.handleLoadEnd}
      />
    );
  }

  render() {
    if (!this.props.imageModalIsVisible) {
      return null;
    }

    const media = this.productMedia()[this.props.itemIndex];

    const classes = classNames('ProductImagesModal', {
      'ProductImagesModal--loading': this.state.imageIsLoading
    });

    return (
      <div ref={this.node} className={classes} onClick={this.handleModalClick}>
        {this.renderArrows()}
        <div
          className="ProductImagesModal-container"
          onClick={this.props.hideImageModal}
        >
          {/* We only expect images, otherwise render nothing */}
          {media.type === 'image' && this.renderImage(media.content)}
          <div className="ProductImagesModal-message">Click To Close</div>
        </div>
      </div>
    );
  }
}

export default provideStoreToComponent(
  connect(
    (state: GlobalState) => {
      return {
        itemIndex: state.productImages.carouselItemIndex,
        imageModalIsVisible: state.productImages.imageModalIsVisible
      };
    },
    (dispatch) => {
      return {
        hideImageModal() {
          dispatch(hideImageModal());
        },
        previousCarouselItem() {
          dispatch(previousCarouselItem(true));
        },
        nextCarouselItem() {
          dispatch(nextCarouselItem(true));
        }
      };
    }
    // @ts-ignore;
  )(ProductImagesModal)
);
