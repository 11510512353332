import React, { createRef } from 'react';
import $ from 'jquery';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

interface Props {
  isVisible: boolean;
  size: 'small' | 'large';
  heading?: string;
  onClose(): void;
}

export default class Modal extends React.PureComponent<Props> {
  static defaultProps = {
    size: 'small'
  };

  private node = createRef<HTMLDivElement>();

  componentDidMount() {
    this.updateBodyClass();

    $('body').on('click.modal', (event) => {
      const { onClose, isVisible } = this.props;
      const { current: node } = this.node;

      if (
        isVisible &&
        node != null &&
        !$.contains(node, event.target) &&
        this.node.current !== event.target
      ) {
        onClose();
      }
    });
  }

  componentDidUpdate() {
    this.updateBodyClass();
  }

  componentWillUnmount() {
    $('body').removeClass('modal-visible');
    $('body').off('click.modal');
  }

  updateBodyClass() {
    const { isVisible } = this.props;

    $('body').toggleClass('modal-visible', isVisible);
  }

  renderHeading() {
    const { heading } = this.props;

    if (isEmpty(heading)) {
      return null;
    }

    return <div className="Modal-heading">{heading}</div>;
  }

  render() {
    const { isVisible, size, onClose, children } = this.props;

    if (isVisible === false) {
      return null;
    }

    const classes = classNames('Modal', {
      'Modal--small': size === 'small',
      'Modal--large': size === 'large'
    });

    return (
      <div className={classes} ref={this.node}>
        <button className="Modal-close" onClick={onClose} />

        {this.renderHeading()}

        <div className="Modal-content">{children}</div>

        <div className="Modal-ghost-content">{children}</div>
      </div>
    );
  }
}
