import React from 'react';

const CartEmptyState: React.FC = () => {
  return (
    <div className="CartEmptyState">
      <div className="shopping-cart-notice">
        <p>Your Shopping Bag is Empty</p>
        <p>
          <a className="button continue" href="/">
            Continue Shopping
          </a>
        </p>
      </div>
    </div>
  );
};

export default CartEmptyState;
