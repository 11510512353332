import React from 'react';

import NavigationButton from './navigation-button';
import SearchButton from './search-button';
import CartLink from './cart-link';
import SearchForm from './search-form';

interface Props {
  logoURL: string;
}

const MobileHeader: React.FC<Props> = (props) => {
  const { logoURL } = props;

  return (
    <header className="MobileHeader">
      <div className="MobileHeader-content">
        <NavigationButton />

        <a href="/" className="MobileHeader-logo">
          <img src={logoURL} />
        </a>

        <div className="MobileHeader-right">
          <SearchButton />

          <a href="/account" className="MobileHeader-account"></a>

          <CartLink />
        </div>
      </div>

      <SearchForm mobile />
    </header>
  );
};

export default MobileHeader;
