import React from 'react';
import classNames from 'classnames';
import { isEmpty, isObject } from 'lodash';

import { connect } from 'react-redux';
import provideStoreToComponent from 'app-provider';
import GlobalState from 'GlobalState';

import { StoreItem } from 'types';

interface Props {
  item: StoreItem;
  imageSize: 'small' | 'medium' | 'large';
}

class ProductsStoreItem extends React.PureComponent<Props> {
  state = {
    isLoaded: false
  };

  image?: HTMLImageElement;

  handleImageLoaded = () => {
    this.setState({
      isLoaded: true
    });

    delete this.image;
  };

  componentDidMount() {
    this.image = new Image();
    this.image.src = this.normalImageUrl();

    this.image.addEventListener('load', this.handleImageLoaded, false);
  }

  componentWillUnmount() {
    if (this.image != null) {
      this.image.removeEventListener('load', this.handleImageLoaded);
    }
  }

  hasHoverImage() {
    const { item } = this.props;

    return !isEmpty(item.hover_image_url);
  }

  normalImageUrl(): string {
    const { item, imageSize } = this.props;
    const isRetina = (window.devicePixelRatio || 1) >= 2;

    let imageUrl: string;

    if (isObject(item.image_url)) {
      switch (imageSize) {
        case 'large':
          imageUrl = isRetina ? item.image_url.large_2x : item.image_url.large;
          break;
        case 'medium':
          imageUrl = isRetina
            ? item.image_url.product_2x
            : item.image_url.product;
          break;
        default:
          imageUrl = isRetina ? item.image_url.small_2x : item.image_url.small;
      }
    } else {
      imageUrl = item.image_url;
    }

    return imageUrl;
  }

  renderNormalImage() {
    return (
      <div className="StoreItem-image StoreItem-image--normal">
        <img src={this.normalImageUrl()} />
      </div>
    );
  }

  renderHoverImage() {
    const { item } = this.props;

    if (this.hasHoverImage()) {
      return (
        <div className="StoreItem-image StoreItem-image--hover">
          <img src={item.hover_image_url} />
        </div>
      );
    }

    return null;
  }

  render() {
    const { item } = this.props;

    let imageBeforeStyle;

    if (item.width && item.height) {
      imageBeforeStyle = {
        paddingTop: `${(item.height / item.width) * 100}%`
      };
    }

    return (
      <div
        className={classNames('StoreItem', {
          'StoreItem--loaded': this.state.isLoaded,
          'StoreItem--has-hover-image': this.hasHoverImage()
        })}
      >
        <a href={item.url}>
          <div className="StoreItem-image-container">
            <div className="StoreItem-image-before" style={imageBeforeStyle} />
            {this.renderNormalImage()}
            {this.renderHoverImage()}
          </div>
          <span className="StoreItem-title">{item.name}</span>
        </a>
      </div>
    );
  }
}

export default provideStoreToComponent(
  connect((state: GlobalState) => {
    return {
      imageSize: state.products.imageSize
    };
  })(ProductsStoreItem)
);
