import React from 'react';

import CartLineItem from './line-item';
import { LineItem } from 'types';

interface Props {
  lineItem: LineItem;
}

const StaticLineItem = (props: Props) => {
  const { lineItem } = props;

  return <CartLineItem lineItem={lineItem} editable={false} />;
};

export default StaticLineItem;
