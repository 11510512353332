import React, { createRef, useState } from 'react';
import classNames from 'classnames';

import { connect } from 'react-redux';
import provideStoreToComponent from 'app-provider';

import { toggleLicenseSection } from 'modules/font-licensing/action-creators';
import { useDeclarationClassName } from 'modules/font-declarations';

const MAX_SIZE = 160;

export interface Props {
  fontStyleId: number;
  defaultText: string;
  defaultSize: number;
  minSize: number;
  name: string;
  lineHeight: string;
  singleLine: boolean;
}

interface ProvidedProps extends Props {
  toggleLicenseSection(): void;
}

const TypeTester: React.FC<ProvidedProps> = (props) => {
  const {
    fontStyleId,
    minSize,
    name,
    singleLine,
    defaultText,
    lineHeight,
    toggleLicenseSection,
    defaultSize
  } = props;

  const className = useDeclarationClassName(fontStyleId);
  const [size, setSize] = useState(defaultSize);

  return (
    <div
      className={classNames('FontTypeTester', {
        'FontTypeTester--single-line': singleLine
      })}
    >
      <div className="FontTypeTester-controls">
        <div className="FontTypeTester-name">{name}</div>

        <input
          className="FontTypeTester-slider"
          type="range"
          value={size}
          min={minSize}
          max={MAX_SIZE}
          onChange={(event) => {
            setSize(parseInt(event.currentTarget.value));
          }}
        />

        <div className="FontTypeTester-size-label">{size} px</div>

        <a
          href="#"
          className="FontTypeTester-link"
          onClick={toggleLicenseSection.bind(null, true)}
        >
          Buy Me
        </a>

        <div className="FontTester-try-me">click on specimen to edit</div>
      </div>

      <div
        className={classNames('FontTypeTester-text-container', {
          'is-loading': className == null
        })}
        style={{ fontSize: size }}
      >
        {className != null && (
          <ContentEditable
            className={classNames('FontTypeTester-text', className)}
            style={{
              lineHeight: `${lineHeight}em`
            }}
            defaultValue={defaultText}
          />
        )}
      </div>

      <div className="FontTypeTester-bottom-name">{name}</div>
    </div>
  );
};

class ContentEditable extends React.Component<{
  defaultValue: string;
  className: string;
  style: object;
}> {
  node = createRef<HTMLDivElement>();
  lastScrollY = 0;

  shouldComponentUpdate() {
    return false;
  }

  select() {
    if (this.node.current == null) {
      return;
    }

    const selection = window.getSelection();

    if (selection == null) {
      return;
    }

    const range = document.createRange();

    range.selectNodeContents(this.node.current);

    selection.removeAllRanges();
    selection.addRange(range);
  }

  handleTouchStart = () => {
    this.lastScrollY = window.scrollY;
  };

  handleTouchEnd = () => {
    if (Math.abs(window.scrollY - this.lastScrollY) < 10) {
      this.select();
    }
  };

  // Convert any content pasted into the field to plain text.
  handlePaste = (event: React.ClipboardEvent) => {
    // @ts-ignore;
    const clipboardData = event.clipboardData || window.clipboardData;

    if (clipboardData && clipboardData.getData) {
      event.preventDefault();

      const text = clipboardData.getData('Text');

      window.document.execCommand('insertText', false, text);
    }
  };

  render() {
    return (
      <div
        ref={this.node}
        contentEditable
        onTouchStart={this.handleTouchStart}
        onTouchEnd={this.handleTouchEnd}
        onPaste={this.handlePaste}
        suppressContentEditableWarning
        {...this.props}
      >
        {this.props.defaultValue}
      </div>
    );
  }
}

export default provideStoreToComponent(
  connect(null, (dispatch) => {
    return {
      toggleLicenseSection(isVisible: boolean) {
        dispatch(toggleLicenseSection(isVisible));
      }
    };
  })(TypeTester)
);
