import React from 'react';

import TypeContainer from '../type-container';
import OptionSelect from '../options';
import StyleSelectAll from '../style-select-all';
import StyleList from '../style-list';
import { connect } from 'react-redux';
import provideStoreToComponent from 'app-provider';
import { addDomainNames } from 'modules/font-licensing/action-creators';
import classNames from 'classnames';

import { FontStyle } from 'types';
import GlobalState from 'GlobalState';

interface Props {
  missingDomainNames: boolean;
  options: { id: number | 'EXTRA'; label: string }[];
  styles: FontStyle[];
  selectedOption: number | 'EXTRA' | null;
  addDomainNames(domainNames: string): void;
}

interface State {
  domainNames: string;
}

class TypeContainerWeb extends React.PureComponent<Props, State> {
  state = { domainNames: '' };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ domainNames: event.target.value }, () => {
      this.props.addDomainNames(this.state.domainNames);
    });
  };

  render() {
    let { options, styles, selectedOption, missingDomainNames } = this.props;

    options = [
      ...options,
      { id: 'EXTRA', label: 'Over 1 million Page Views/Month' }
    ];

    let hint = null;

    if (selectedOption === 'EXTRA') {
      hint = (
        <div className="FontLicensingSection-hint">
          <div className="FontLicensingSection-hint-content">
            <div>HAVE MORE THAN 1 MILLION PAGE VIEWS/MONTH?</div>
            <div>
              <a href="/contact">CONTACT US</a> FOR PRICING.
            </div>
          </div>
        </div>
      );
    }

    return (
      <TypeContainer label="Web" licenseType="web">
        <OptionSelect options={options} labelSuffix="Page Views/Month" />

        <div>
          <input
            value={this.state.domainNames}
            className={classNames('FontLicensingSection-domain-names-input', {
              error: missingDomainNames
            })}
            onChange={this.handleChange}
          />

          {missingDomainNames && (
            <span className="FontLicensingSection-domain-names-error">
              Please enter a domain name
            </span>
          )}
        </div>

        <div className="FontLicensingModalButtons FontDomainLabels">
          <span className="FontLicensingSection-domain-names">Domain name</span>
          <span className="FontLicensingSection-domain-names">(ONE DOMAIN PER LICENSE)</span>
        </div>

        <div className="FontLicensingSection-styles-list">
          <StyleSelectAll styles={styles} />
          <StyleList styles={styles} />
          {hint}
        </div>
      </TypeContainer>
    );
  }
}

export default provideStoreToComponent(
  connect(
    (state: GlobalState) => {
      return {
        missingDomainNames: state.fontLicensing.missingDomainNames,
        domainNames: state.domainNames
      };
    },
    (dispatch) => {
      return {
        addDomainNames(domainNames: string) {
          dispatch(addDomainNames(domainNames));
        }
      };
    }
  )(TypeContainerWeb)
);
