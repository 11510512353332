import React from 'react';
import numeral from 'numeral';

import { Order } from 'types';

interface Props {
  order: Order;
}

const CartSubtotal: React.FC<Props> = (props) => {
  const { order } = props;

  const subtotal = order.line_items.reduce((sum, item) => {
    return sum + item.total;
  }, 0);

  const formattedSubtotal = numeral(subtotal).format('$0,0.00');

  return (
    <div className="CartSubtotal">
      <div className="CartSubtotal-inside">Subtotal {formattedSubtotal}</div>
    </div>
  );
};

export default CartSubtotal;
