import React from 'react';

import { connect } from 'react-redux';
import provideStoreToComponent from 'app-provider';

import SearchFormResult from './search-form-result';
import GlobalState from 'GlobalState';

interface Props {
  searchResults: GlobalState['products']['searchResults'];
  searchTerm: string;
  selectedResultId: number;
}

export const SearchFormResults: React.FC<Props> = (props) => {
  const { searchResults, searchTerm, selectedResultId } = props;

  if (searchTerm == null) {
    return null;
  }

  const highlightTerms = searchTerm.toLowerCase().split(/\s+/);

  return (
    <div className="SearchFormResults">
      <ul className="SearchFormResults-list">
        {searchResults.map((product) => {
          return (
            <SearchFormResult
              key={product.id}
              product={product}
              highlightTerms={highlightTerms}
              selectedResultId={selectedResultId}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default provideStoreToComponent(
  connect((state: GlobalState) => {
    return {
      searchResults: state.products.searchResults,
      selectedResultId: state.products.selectedResultId,
      searchTerm: state.products.searchTerm
    };
  })(SearchFormResults)
);
