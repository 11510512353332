import React from 'react';

import { connect } from 'react-redux';
import provideStoreToComponent from 'app-provider';

import { formatCents } from 'utils/money';
import Context from './context';
import { LicenseType } from 'types';
import GlobalState from 'GlobalState';

interface Props {
  label: string;
  licenseType: LicenseType;
  totals: GlobalState['fontLicensing']['totals'];
}

const FontLicensingTypeContainer: React.FC<Props> = (props) => {
  const { totals, licenseType, label, children } = props;
  const total = totals[licenseType];
  const formattedTotal = formatCents(total);

  return (
    <Context.Provider
      value={{
        licenseType
      }}
    >
      <div className="FontLicensingTypeContainer">
        <h1 className="FontLicensingTypeContainer-heading">{label}</h1>
        <div className="FontLicensingTypeContainer-content">
          <div className="FontLicensingTypeContainer-total">
            {formattedTotal}
          </div>
          {children}
        </div>
      </div>
    </Context.Provider>
  );
};

export default provideStoreToComponent(
  connect((state: GlobalState) => {
    return {
      totals: state.fontLicensing.totals
    };
  })(FontLicensingTypeContainer)
);
