import React from 'react';
import classNames from 'classnames';
import { range } from 'lodash';

import Context from './context';

const CarouselItemMap: React.FC = () => {
  return (
    <Context.Consumer>
      {({ itemCount, itemIndex }) => {
        if (itemCount < 2) {
          return null;
        }

        return (
          <div className="CarouselItemMap">
            {range(itemCount).map((index) => {
              const classes = classNames('CarouselItemMap-item', {
                'CarouselItemMap-item--active': index === itemIndex
              });

              return <span key={index} className={classes} />;
            })}
          </div>
        );
      }}
    </Context.Consumer>
  );
};

export default CarouselItemMap;
