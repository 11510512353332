import React from 'react';
import { groupBy, sortBy, map } from 'lodash';

import { connect } from 'react-redux';
import provideStoreToComponent from 'app-provider';

import SearchResultsGroup from './search-results-group';
import GlobalState from 'GlobalState';
import { StoreItem } from 'types';

const TAXONOMY_ORDER = ['Fonts', 'Store', 'Work'];

interface Props {
  items: StoreItem[];
  imageSize: 'small' | 'medium' | 'large';
  selectedTaxonId: number;
  keywords: string;
  limit: number;
}

class SearchResults extends React.Component<Props> {
  renderGroups() {
    const { imageSize } = this.props;

    const groupedItems = sortBy(
      map(groupBy(this.props.items, 'taxonomy_name'), (items, heading) => {
        return { heading, items };
      }),
      (group) => {
        return TAXONOMY_ORDER.indexOf(group.heading);
      }
    );

    return map(groupedItems, (group) => {
      return (
        <SearchResultsGroup
          key={group.heading}
          heading={group.heading}
          items={group.items}
          imageSize={imageSize}
        />
      );
    });
  }

  render() {
    if (this.props.items.length === 0) {
      return <div className="no-products-notice">No Products Found!</div>;
    }

    return <div>{this.renderGroups()}</div>;
  }
}

export default provideStoreToComponent(
  connect((state: GlobalState) => {
    return {
      imageSize: state.products.imageSize,
      items: state.products.items,
      selectedTaxonId: state.products.selectedTaxonId
    };
  })(SearchResults)
);
