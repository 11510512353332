import React, { createRef } from 'react';
import $ from 'jquery';
import classNames from 'classnames';
import { get } from 'lodash';

type HTMLSelectElementProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>;

interface Props extends HTMLSelectElementProps {
  includeBlank?: boolean;
}

interface State {
  focused: boolean;
  selectedLabel: string | null;
}

class Select extends React.Component<Props, State> {
  static defaultProps = {
    includeBlank: false
  };

  state = {
    focused: false,
    selectedLabel: null
  };

  private selectNode = createRef<HTMLSelectElement>();

  componentDidMount() {
    this.updateSelectedLabel();
  }

  componentDidUpdate() {
    this.updateSelectedLabel();
  }

  updateSelectedLabel() {
    if (this.selectNode.current == null) {
      return;
    }

    const selectedLabel = $(this.selectNode.current)
      .find('option:selected')
      .text();

    if (selectedLabel !== this.state.selectedLabel) {
      this.setState({ selectedLabel });
    }
  }

  handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.updateSelectedLabel();

    const { onChange } = this.props;

    if (onChange != null) {
      onChange(event);
    }
  };

  handleFocus = (event: React.FocusEvent<HTMLSelectElement>) => {
    this.setState({
      focused: true
    });

    const { onFocus } = this.props;

    if (onFocus != null) {
      onFocus(event);
    }
  };

  handleBlur = (event: React.FocusEvent<HTMLSelectElement>) => {
    this.setState({
      focused: false
    });

    const { onBlur } = this.props;

    if (onBlur != null) {
      onBlur(event);
    }
  };

  renderBlankOption() {
    if (this.props.includeBlank) {
      return <option />;
    }

    return null;
  }

  renderSelect() {
    const { includeBlank, children, ...selectProps } = this.props;

    return (
      <select
        ref={this.selectNode}
        {...selectProps}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
      >
        {this.renderBlankOption()}
        {children}
      </select>
    );
  }

  render() {
    const { disabled } = this.props;
    const { focused } = this.state;

    const classes = classNames('Select', {
      'Select--disabled': disabled,
      'Select--focused': focused
    });

    const isTestEnvironment = get(window, 'ENV.railsEnv') === 'test';

    if (isTestEnvironment) {
      return this.renderSelect();
    }

    return (
      <span className={classes} tabIndex={1}>
        {this.renderSelect()}

        <span className="Select-inside">
          <span className="Select-value">{this.state.selectedLabel}</span>
          <span className="Select-arrow" />
        </span>
      </span>
    );
  }
}

export default Select;
