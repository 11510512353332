import React, { forwardRef } from 'react';

import Tooltip from 'components/core/tooltip';
import Glyphs from './glyphs';
import { FontStyle } from 'types';

interface Props {
  styles: FontStyle[];
  isVisible: boolean;
}

const FontFamilyGlyphsSection = forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { styles, isVisible } = props;

    if (isVisible) {
      return (
        <div ref={ref} className="glyphs">
          <div className="glyphs-header">
            <h2>Glyphs</h2>

            <Tooltip>
              This chart may not show all of glyphs from the font. If a font has
              a features section, you’ll see the additional goodies such as
              alternate characters, small caps, different figure styles and
              ligatures.
            </Tooltip>
          </div>

          <Glyphs styles={styles} />
        </div>
      );
    }

    return null;
  }
);

export default FontFamilyGlyphsSection;
