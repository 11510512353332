import React, { createRef } from 'react';
import $ from 'jquery';

import { connect } from 'react-redux';
import provideStoreToComponent from 'app-provider';
import globalData from 'utils/global-data';

import SectionHeader from './section-header';
import TypeContainerDesktop from './type-container/desktop';
import TypeContainerWeb from './type-container/web';
import LicensingModal from './modal';
import {
  toggleLicenseSection,
  calculateLicenseTotalsStart
} from 'modules/font-licensing/action-creators';

import GlobalState from 'GlobalState';
import { LicenseOption, FontStyle } from 'types';

interface Props {
  name: string;
  isVisible: boolean;
  selectedOption: GlobalState['fontLicensing']['selectedOption'];
  styles: FontStyle[];
  options: {
    desktop: LicenseOption[];
    web: LicenseOption[];
  };
  calculateLicenseTotals(): void;
  toggleLicenseSection(isVisible: boolean): void;
}

class FontLicensingSection extends React.PureComponent<Props> {
  static defaultProps = {
    options: globalData().licenseOptions
  };

  node = createRef<HTMLDivElement>();
  headerNode = createRef<HTMLDivElement>();

  componentDidMount() {
    const { calculateLicenseTotals } = this.props;

    calculateLicenseTotals();
    $('body').addClass('FontLicensingModalVisible');
  }

  componentWillUnmount() {
    $('body').removeClass('FontLicensingModalVisible');
  }

  render() {
    const { name, styles, options, selectedOption, toggleLicenseSection } =
      this.props;

    const onClose = toggleLicenseSection.bind(null, false);

    return (
      <div className="FontLicensingSection" ref={this.node}>
        <div className="FontLicensingSection-container">
          <SectionHeader ref={this.headerNode} name={name} onClose={onClose} />

          <div className="FontLicensingSection-type-containers">
            <TypeContainerDesktop
              options={options.desktop}
              styles={styles}
              selectedOption={selectedOption.desktop}
            />

            <TypeContainerWeb
              options={options.web}
              styles={styles}
              selectedOption={selectedOption.web}
            />
          </div>

          <div className="FontLicensingSection-type-disclaimer">
            Pricing for a limited time and subject to change.
          </div>
        </div>

        <LicensingModal />
      </div>
    );
  }
}

export default provideStoreToComponent(
  connect(
    (state: GlobalState) => {
      return {
        isVisible: state.fontLicensing.isVisible,
        selectedOption: state.fontLicensing.selectedOption
      };
    },
    (dispatch) => {
      return {
        calculateLicenseTotals() {
          dispatch(calculateLicenseTotalsStart());
        },
        toggleLicenseSection(isVisible: boolean) {
          dispatch(toggleLicenseSection(isVisible));
        }
      };
    }
  )(FontLicensingSection)
);
