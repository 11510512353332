import React from 'react';

import TypeContainer from '../type-container';
import OptionSelect from '../options';
import StyleSelectAll from '../style-select-all';
import StyleList from '../style-list';
import { FontStyle } from 'types';

interface Props {
  styles: FontStyle[];
  options: { id: number | 'EXTRA'; label: string }[];
  selectedOption: number | 'EXTRA' | null;
}

class TypeContainerDesktop extends React.PureComponent<Props> {
  render() {
    const { styles, selectedOption } = this.props;
    let { options } = this.props;

    options = [...options, { id: 'EXTRA', label: '10+' }];

    let hint = null;

    if (selectedOption === 'EXTRA') {
      hint = (
        <div className="FontLicensingSection-hint">
          <div className="FontLicensingSection-hint-content">
            <div>HAVE MORE THAN 15 DEVICES?</div>
            <div>
              <a href="/contact">CONTACT US</a> FOR PRICING
            </div>
          </div>
        </div>
      );
    }

    return (
      <TypeContainer label="Desktop" licenseType="desktop">
        <OptionSelect
          options={options}
          labelSuffix="Devices"
          labelSuffixSingular="Device" />

        <div className="FontLicensingSection-styles-list">
          <StyleSelectAll styles={styles} />

          <StyleList styles={styles} />

          {hint}
        </div>
      </TypeContainer>
    );
  }
}

export default TypeContainerDesktop;
