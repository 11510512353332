import React, { forwardRef } from 'react';
import TypeTester, { Props as TypeTesterProps } from './type-tester';

interface Props {
  typeTesters: TypeTesterProps[];
  isVisible: boolean;
}

const FontFamilyTypeTestersSection = forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { typeTesters, isVisible } = props;

    if (isVisible) {
      const testers = typeTesters.map((tester, index) => {
        return <TypeTester key={index} {...tester} />;
      });

      return (
        <div ref={ref} className="testers">
          {testers}
        </div>
      );
    }

    return null;
  }
);

export default FontFamilyTypeTestersSection;
