interface SelectCarouselItemAction {
  type: 'SELECT_CAROUSEL_ITEM';
  index: number;
}

export function selectCarouselItem(index: number): SelectCarouselItemAction {
  return { type: 'SELECT_CAROUSEL_ITEM', index };
}

interface NextCarouselItemAction {
  type: 'NEXT_CAROUSEL_ITEM';
  imagesOnly: boolean;
}

export function nextCarouselItem(
  imagesOnly: boolean = false
): NextCarouselItemAction {
  return { type: 'NEXT_CAROUSEL_ITEM', imagesOnly };
}

interface PreviousCarouselItemAction {
  type: 'PREVIOUS_CAROUSEL_ITEM';
  imagesOnly: boolean;
}

export function previousCarouselItem(
  imagesOnly: boolean = false
): PreviousCarouselItemAction {
  return { type: 'PREVIOUS_CAROUSEL_ITEM', imagesOnly };
}

interface ShowImageModalAction {
  type: 'SHOW_IMAGE_MODAL';
}

export function showImageModal(): ShowImageModalAction {
  return { type: 'SHOW_IMAGE_MODAL' };
}

interface HideImageModalAction {
  type: 'HIDE_IMAGE_MODAL';
}

export function hideImageModal(): HideImageModalAction {
  return { type: 'HIDE_IMAGE_MODAL' };
}

interface UpdateSelectedVariantAction {
  type: 'UPDATE_SELECTED_VARIANT';
  variantId: number | null;
}

export function updateSelectedVariant(
  variantId: number | null
): UpdateSelectedVariantAction {
  return { type: 'UPDATE_SELECTED_VARIANT', variantId };
}

export type CarouselAction =
  | SelectCarouselItemAction
  | NextCarouselItemAction
  | PreviousCarouselItemAction
  | ShowImageModalAction
  | HideImageModalAction
  | UpdateSelectedVariantAction;
