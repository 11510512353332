import React from 'react';
import $ from 'jquery';
import { includes } from 'lodash';
import classNames from 'classnames';

import { connect } from 'react-redux';
import provideStoreToComponent from 'app-provider';

import CenteredResponsiveImage from 'components/core/centered-responsive-image';
import { currentProduct, selectedVariant } from 'modules/product/getters';

import { Variant, ProductImage, isProductMediaImage } from 'types';
import GlobalState from 'GlobalState';

interface Props {
  currentProduct: GlobalState['product'];
  selectedVariant?: Variant;
  lineItemCreated?: boolean;
}

interface State {
  isVisible: boolean;
}

class ProductCartPopover extends React.Component<Props, State> {
  state = {
    isVisible: false
  };

  hideTimeout?: number;

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.lineItemCreated === false &&
      this.props.lineItemCreated === true
    ) {
      this.show();
    }
  }

  show = () => {
    clearTimeout(this.hideTimeout);

    this.setState({
      isVisible: true
    });

    $('body').addClass('cart-popover-visible');

    if (window.screen.width > 650) {
      this.delayedHide();
    }
  };

  hide = () => {
    this.setState({
      isVisible: false
    });

    $('body').removeClass('cart-popover-visible');
  };

  delayedHide = () => {
    clearTimeout(this.hideTimeout);

    this.hideTimeout = window.setTimeout(this.hide, 2500);
  };

  preventHide = () => {
    clearTimeout(this.hideTimeout);
  };

  renderImage() {
    let image: ProductImage | undefined;

    const { currentProduct, selectedVariant } = this.props;

    if (currentProduct == null) {
      throw new Error('Missing current product.');
    }

    if (selectedVariant != null) {
      const imageMedia = currentProduct.media.find((media) => {
        return (
          isProductMediaImage(media) &&
          includes(media.variant_ids, selectedVariant.id)
        );
      });

      if (isProductMediaImage(imageMedia)) {
        image = imageMedia.content;
      }
    }

    if (image == null) {
      const imageMedia = currentProduct.media.find((media) => {
        return isProductMediaImage(media);
      });

      if (isProductMediaImage(imageMedia)) {
        image = imageMedia.content;
      }
    }

    if (image != null) {
      const srcset: [string, string][] = [
        [image.urls.small, '1x'],
        [image.urls.small_2x, '3x']
      ];

      return (
        <div className="ProductCartPopover-image">
          <CenteredResponsiveImage srcset={srcset} />
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    const classes = classNames('ProductCartPopover', {
      'ProductCartPopover--visible': this.state.isVisible
    });

    return (
      <div
        className={classes}
        onMouseOver={this.preventHide}
        onMouseOut={this.delayedHide}
      >
        <a href="#" className="ProductCartPopover-close" onClick={this.hide} />
        <div className="ProductCartPopover-content">
          {this.renderImage()}
          <div className="ProductCartPopover-message">Item added to bag!</div>
        </div>

        <div className="ProductCartPopover-actions">
          <button onClick={this.hide}>Keep Shopping</button>
          <a href="/cart">View Bag</a>
        </div>
      </div>
    );
  }
}

export default provideStoreToComponent(
  connect((state: GlobalState) => {
    const product = currentProduct(state);

    return {
      selectedVariant: selectedVariant(state),
      currentProduct: product,
      lineItemCreated: product != null && product.lineItemCreated
    };
  })(ProductCartPopover)
);
