import React from 'react';
import { connect } from 'react-redux';
import provideStoreToComponent from 'app-provider';
import { dismissFlashMessage } from 'modules/flash-messages/action-creators';

import { Message } from 'types';

interface Props {
  message: Message;
  dismissFlashMessage?: Function;
}

class FlashMessage extends React.Component<Props> {
  dismissTimeout?: number;

  componentDidMount() {
    this.deferDismiss();
  }

  dismiss = () => {
    clearTimeout(this.dismissTimeout);

    const { message, dismissFlashMessage } = this.props;

    dismissFlashMessage && dismissFlashMessage(message);
  };

  deferDismiss() {
    clearTimeout(this.dismissTimeout);

    this.dismissTimeout = window.setInterval(this.dismiss, 5000);
  }

  render() {
    return (
      <div className="FlashMessage">
        <span
          className="FlashMessage-content"
          dangerouslySetInnerHTML={{ __html: this.props.message.content }}
        />

        <button className="FlashMessage-dismiss" onClick={this.dismiss} />
      </div>
    );
  }
}

export default provideStoreToComponent(
  connect(null, (dispatch) => {
    return {
      dismissFlashMessage(message: Message) {
        dispatch(dismissFlashMessage(message));
      }
    };
  })(FlashMessage)
);
