import '../styles/application.scss';

import 'react_ujs';
import jQuery from 'jquery';

import store from '../store';
import StripeLoader from '../stripe-loader';
import * as Components from '../components';
import * as flashMessageActions from '../modules/flash-messages/action-creators';

window.jQuery = jQuery;
window.$ = jQuery;

window.store = store;
window.StripeLoader = StripeLoader;
window.Components = Components;
window.flashMessageActions = flashMessageActions;

import 'initializers/ajax';
import 'initializers/body-classes';
import 'initializers/form-recaptcha';
