import React from 'react';
import { values } from 'lodash';
import { connect } from 'react-redux';
import provideStoreToComponent from 'app-provider';

import { formatCents } from 'utils/money';

import GlobalState from 'GlobalState';

interface Props {
  totals: GlobalState['fontLicensing']['totals'];
}

const FontLicensingTotalPrice: React.FC<Props> = (props) => {
  const { totals } = props;
  const totalPrice = values(totals).reduce((sum, value) => {
    return sum + value;
  }, 0);

  return (
    <div className="FontLicensingTotalPrice">{formatCents(totalPrice)}</div>
  );
};

export default provideStoreToComponent(
  connect((state: GlobalState) => {
    return {
      totals: state.fontLicensing.totals
    };
  })(FontLicensingTotalPrice)
);
