import React from 'react';
import ResponsiveImage, {
  Props as ResponsiveImageProps
} from './responsive-image';

interface Props extends Omit<Omit<ResponsiveImageProps, 'ref'>, 'loadImage'> {
  loadImage?: boolean;
}

/*
 * This is meant to be used in situations where a `ResponsiveImage` needs to be rendered within a
 * containing element to center it within a space.
 *
 * TODO: Right now the CSS styles applied to it are scattered around the project and should be
 *       unified.
 */
const CenteredResponsiveImage: React.FC<Props> = (props) => {
  return (
    <div className="CenteredResponsiveImage">
      <ResponsiveImage {...props} />
    </div>
  );
};

export default CenteredResponsiveImage;
