import React from 'react';
import CarouselContainer from 'components/core/carousel/container';
import CarouselItems from 'components/core/carousel/items';
import GlyphsItem from './glyphs-item';
import { FontStyle } from 'types';

interface Props {
  styles: FontStyle[];
}

const FontFamilyGlyphs: React.FC<Props> = (props) => {
  const { styles } = props;

  const items = styles.map((style, index) => {
    return <GlyphsItem key={index} style={style} />;
  });

  return (
    <div className="FontGlyphs">
      <CarouselContainer itemCount={styles.length}>
        <CarouselItems>{items}</CarouselItems>
      </CarouselContainer>
    </div>
  );
};

export default FontFamilyGlyphs;
