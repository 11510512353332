import React, { createRef } from 'react';
import classNames from 'classnames';
import $ from 'jquery';

import { connect } from 'react-redux';
import provideStoreToComponent from 'app-provider';

import {
  updateSearchTerm,
  selectNextResult,
  selectPreviousResult
} from 'modules/products/action-creators';

import SearchFormInput from './search-form-input';
import SearchFormResults from './search-form-results';
import GlobalState from 'GlobalState';

interface Props {
  mobile: boolean;
  isVisible: boolean;
  searchTerm?: string;
  searchResults: GlobalState['products']['searchResults'];
  selectedResultId: number;
  selectNextResult(): void;
  selectPreviousResult(): void;
  updateSearchTerm(term: string): void;
}

class SearchForm extends React.PureComponent<Props> {
  state = {
    isFocused: false
  };

  node = createRef<HTMLDivElement>();
  inputNode = createRef<HTMLInputElement>();

  componentWillUpdate(nextProps: Props) {
    if (this.props.isVisible === false && nextProps.isVisible === true) {
      this.setState({ isFocused: true });
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isVisible === false && this.props.isVisible === true) {
      if (this.inputNode.current != null) {
        this.inputNode.current.select();
      }

      setTimeout(
        () => window.addEventListener('click', this.handleClickOutside, false),
        0
      );
    } else if (prevProps.isVisible === true && this.props.isVisible === false) {
      window.removeEventListener('click', this.handleClickOutside, false);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickOutside, false);
  }

  handleClickOutside = (event: MouseEvent) => {
    const { isFocused } = this.state;
    const { current: node } = this.node;

    if (node == null) {
      return;
    }

    const $self = $(node);

    if (
      isFocused &&
      event.target instanceof HTMLElement &&
      $self.find(event.target).length === 0
    ) {
      this.setState({ isFocused: false });
    }
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.updateSearchTerm(event.currentTarget.value);
  };

  handleInputFocus = () => {
    this.setState({ isFocused: true });
  };

  handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const {
      selectPreviousResult,
      selectNextResult,
      searchResults,
      selectedResultId
    } = this.props;

    if (event.keyCode === 38) {
      event.preventDefault();
      selectPreviousResult();
    } else if (event.keyCode === 40) {
      event.preventDefault();
      selectNextResult();
    } else if (event.keyCode === 13) {
      const selectedResult = searchResults.find(
        (result) => result.id === selectedResultId
      );

      if (selectedResult != null) {
        event.preventDefault();
        window.location.href = selectedResult.path;
      }
    }
  };

  renderButton() {
    const { mobile } = this.props;

    if (mobile) {
      return null;
    }

    return (
      <div className="SearchForm-button">
        <button type="submit">Search</button>
      </div>
    );
  }

  render() {
    const { isVisible, mobile, searchTerm } = this.props;

    const style = {
      display: isVisible ? 'block' : 'none'
    };

    const classes = classNames('SearchForm', {
      'SearchForm--mobile': mobile
    });

    return (
      <div className={classes} style={style}>
        <div className="SearchForm-form-wrapper">
          <form className="SearchForm-form" action="/" method="get">
            <SearchFormInput
              ref="input"
              value={searchTerm || ''}
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus}
              onKeyDown={this.handleInputKeyDown}
            />

            {this.renderButton()}
          </form>
        </div>
        <SearchFormResults />
      </div>
    );
  }
}

export default provideStoreToComponent(
  connect(
    (state: GlobalState) => {
      return {
        isVisible: state.options.searchIsVisible,
        searchTerm: state.products.searchTerm,
        searchResults: state.products.searchResults,
        selectedResultId: state.products.selectedResultId
      };
    },
    (dispatch) => {
      return {
        updateSearchTerm(searchTerm: string) {
          dispatch(updateSearchTerm(searchTerm));
        },
        selectNextResult() {
          dispatch(selectNextResult());
        },
        selectPreviousResult() {
          dispatch(selectPreviousResult());
        }
      };
    }
  )(SearchForm)
);
