import { find } from 'lodash';
import globalData from 'utils/global-data';
import GlobalState from 'GlobalState';

function variantById(id: number | null) {
  if (id == null) {
    return;
  }

  const { product } = globalData();

  if (product == null || id == null) {
    return null;
  }

  return find(product.variants, { id });
}

export const currentProduct = (state: GlobalState) => {
  return state.product;
};

export const selectedVariant = (state: GlobalState) => {
  if (state.product == null) {
    return;
  }

  return variantById(state.product.selectedVariantId);
};
